import React, { useState, useEffect } from 'react'
import { InputForm, CheckBox, Button, SelectInputForm, RandomHex } from '../../../components'
import { postData, getOrgData } from '../../../services'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import icons from '../../../ultils/icons'


const { Refresh, Copy } = icons
const AddDevice = ({ val, setValue }) => {
    const { id } = useParams()//get id from param path (react router)
    const { token } = useSelector(state => state.auth)
    const [invalidFields, setInvalidFields] = useState()
    const [device, setDevice] = useState()
    const navigate = useNavigate()
    const [stringOrArray, setStringOrArray] = useState()
    let options = []
    useEffect(() => {
        // console.log(val.devices)
        setDevice(val.devices)
    }, [val.devices])
    // console.log(device)
    for (let i = 0; i < device?.length; i++) {
        options[i] = {
            value: device[i].id,
            label: device[i].name
        }
    }
    console.log(options)
    const [payload, setPayload] = useState({
        applicationID: id,
        description: "",
        devEUI: "",
        deviceProfileID: "",
        isDisabled: false,
        name: "",
        referenceAltitude: 0,
        skipFCntCheck: false,
        tags: {},
        variables: {}
    })

    const types = ['General', 'Variables', 'Tags']
    const [active, setActive] = useState(types[0])
    const hexSpace = (value) => {
        let inputVal = value.replace(/ /g, ""); //remove all the empty spaces in the input
        // Get nd array of 4 digits per an element EX: ["42", "42", ...]
        const splits = inputVal.match(/.{1,2}/g);
        let spacedNumber = "";
        if (splits) {
            spacedNumber = splits.join(" "); // Join all the splits with an empty space
        }
        // console.log(spacedNumber)
        return spacedNumber
    }
    const hexArray = (value) => {
        let inputVal = value.replace(/0x/g, "").replace(/ /g, ""); //remove all the empty spaces in the input
        // Get nd array of 4 digits per an element EX: ["42", "42", ...]
        const splits = inputVal.match(/.{1,2}/g);
        let hexArray = []
        if (splits) {
            splits.map((value) => {
                if (value !== '0x') {
                    hexArray.push(`0x${value}`)
                    return hexArray
                } else {
                    return hexArray.push(value)
                }
            })
        }
        // console.log(hexArray)
        return hexArray
    }

    const onChange = e => {
        const input = e.currentTarget.value;
        console.log('input', input.toString())
        if (!stringOrArray) {
            if (/^[0-9a-f_ ]+$/.test(input) || input === "") {
                // only allow hex and whitespace
                let temp = input.toString().replace(/0x/g, "").replace(/,/g, "")
                if (e.target.id === 'nwkSEncKey') {
                    setPayload(pre => {
                        return { ...pre, 'devEUI': temp }
                    })
                }
            } else {
                if (/^[0-9a-f_,x]+$/.test(input) || input === "") {
                    // only allow hex 0x..
                    let temp = input.toString().replace(/0x/g, "").replace(/,/g, "")
                    if (e.target.id === 'nwkSEncKey') {
                        setPayload(pre => {
                            return { ...pre, 'devEUI': temp }
                        })
                    }
                }

            }
        }
    }

    const handleCheckbox = (e) => {
        if (e === 'isDisabled') {
            setPayload(pre => {
                return { ...pre, 'isDisabled': !payload.isDisabled }
            })
        } else if (e === 'skipFCntCheck') {
            setPayload(pre => {
                return { ...pre, 'skipFCntCheck': !payload.skipFCntCheck }
            })
        }
    }

    const postDevice = async (token, payload) => {
        await postData.postDevice(token, payload)
            .then((val) => {
                console.log(val)
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': val.data.msg, 'error': false }
                })
                navigate(-1)
            })
            .catch((err) => {
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': err.response.data.msg, 'error': true }
                })
            })
    }
    const handleSubmit = async () => {
        await postDevice(token, payload)
        console.log(payload)
    }
    const handleRefresh = (e) => {
        var hex
        if (e.currentTarget.id === 'devEUI') {
            hex = RandomHex.randomHexString(16)
            console.log(hex)
            setPayload(pre => {
                return { ...pre, 'devEUI': hex }
            })
        }
    }

    const handleCopy = (e) => {
        if (e.currentTarget.id === 'devEUI') {
            if (!stringOrArray) {
                let temp = navigator.clipboard.writeText(hexSpace(payload.devEUI))
                console.log(temp)
            } else {
                let temp = navigator.clipboard.writeText(hexArray(payload.devEUI))
                console.log(temp)
            }
        }
    }

    const changeStringOrArray = (e) => {
        if (e.target.id === 'devEUI') {
            setStringOrArray(!stringOrArray)
        }
        console.log(stringOrArray)
    }

    return (
        <div className='w-full h-full'>
            <div className='p-[10px] text-xl font-medium text-textColor inline-block align-text-bottom'>Add Device</div>
            <div className='border px-[10px] py-[20px] text-textColor rounded-md'>
                <div className='flex flex-nowrap items-centers justify-center overflow:hidden '>
                    {types.map(type => (
                        <button
                            className={`${active === type ? 'bg-red-200' : 'bg-gray-200'} min-w-[200px] w-1/6`}
                            key={type}
                            active={active === type}
                            onClick={() => setActive(type)}
                        >
                            {type}
                        </button>
                    ))}
                </div>
                {
                    active === 'General' &&
                    <div>
                        <InputForm type='name' label={'Device Name'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.name} setValue={setPayload} />
                        <div className='text-gray-400 text-sm mt-[5px]'>(Only contain text number and dashes).</div>
                        <InputForm type='description' label={'Device description'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.description} setValue={setPayload} />

                        <div className='py-2'>
                            <h2 className='text-xs'>Device EUI</h2>
                            <div className='flex items-center'>
                                <input
                                    className='bg-blue-100 outline-none rounded p-1 w-full'
                                    id="numeroSerie"
                                    name="num_serie"
                                    minLength={stringOrArray ? 19 : 11}
                                    maxLength={stringOrArray ? 19 : 11}
                                    value={stringOrArray ? hexArray(payload.devEUI) : hexSpace(payload.devEUI)}
                                    onChange={onChange}
                                    placeholder={'Input at least 16 HEX character  '}
                                />
                                <div id="devEUI" className='mx-[10px] cursor-pointer' onClick={(e) => { changeStringOrArray(e) }}>{stringOrArray ? 'Arr' : 'Str'}</div>
                                <FontAwesomeIcon className='mx-[10px] cursor-pointer' id="devEUI" icon={Refresh} color={'gray-500'} onClick={(e) => handleRefresh(e)} />
                                <FontAwesomeIcon className='mx-[10px] cursor-pointer' id="devEUI" icon={Copy} color={'gray-500'} onClick={(e) => handleCopy(e)} />
                            </div>
                        </div>
                        <div className='my-[10px]'>
                            <h2 className='text-xs'>Device-profile</h2>
                            <SelectInputForm options={options} val={'deviceProfileID'} setValue={setPayload} />
                        </div>
                        <CheckBox id={'skipFCntCheck'} label={'Disable frame-counter validation'} checked={payload.skipFCntCheck} onchange={e => handleCheckbox(e.target.id)} />
                        <CheckBox id={'isDisabled'} label={'Device is disabled'} checked={payload.isDisabled} onchange={e => handleCheckbox(e.target.id)} />
                    </div>
                }
                {
                    active === 'Variables' &&
                    <div>
                        Variables can be used to substitute placeholders in for example integrations, e.g. in case an integration requires the configuration of a device specific token.
                    </div>
                }
                {
                    active === 'Tags' &&
                    <div>
                        Tags can be used as device filters and are exposed on events as additional meta-data for aggregation.
                    </div>
                }
                <div className='flex justify-end my-2'>
                    <Button text={'Create Device'} onClick={handleSubmit} />
                </div>
            </div>
        </div>
    )
}
export default AddDevice