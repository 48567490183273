import React, { memo } from 'react'

const InputForm = ({ id, required, label, value, setValue, type, invalidFields, setInvalidFields, typeKeyboard, inputSize, onchange, readOnly, labelColor, labelSize, disable }) => {
    return (
        <div>
            <label htmlFor="phone" className={`${labelSize ? labelSize : 'text-xs'} text-${labelColor}`}>{label}</label>
            <input
                required={required && required}
                readOnly={readOnly ? readOnly : false}
                type={`${typeKeyboard ? typeKeyboard : 'text'}`}
                id={id ? id : ''}
                maxLength={inputSize ? inputSize : ''}
                className='outline-none bg-blue-100 p-2 rounded-md w-full'
                value={value}
                disabled={disable ? disable : false}
                onChange={onchange ? onchange : (e) => setValue(prev => ({ ...prev, [type]: e.target.value }))}
                onFocus={() => setInvalidFields([])}
            />
            {invalidFields.length > 0 && invalidFields.some(i => i.name === type) && <small className='text-red-400'>{invalidFields.find(i => i.name === type)?.message}</small>}
        </div>
    )
}

export default memo(InputForm)