import React, { memo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Button = ({ id, text, textColor, bgColor, onClick, icAfter, size, hover, border, iconColor }) => {
    return (
        <button
            type='button'
            className={`p-2  ${textColor} ${bgColor} ${border} rounded-md  ${size ? size : 'min-w-[100px]'} ${hover} cursor-pointer`}
            onClick={onClick}
            id={id}
        >
            {icAfter && <FontAwesomeIcon icon={icAfter} color={iconColor ? iconColor : 'gray-500'} />}
            {text}
        </button>
    )
}

export default memo(Button)