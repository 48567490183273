import React, { useState, useCallback, useEffect } from 'react'
import { InputForm, Button, CheckBox } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { postData, getOrgData } from '../../services'

const CreateOrg = ({ value, setValue }) => {

    const [invalidFields, setInvalidFields] = useState([])
    const { isLoggedIn, token } = useSelector(state => state.auth)

    const [errMSG, setErrMSG] = useState({
        msg: '',
    });

    const navigate = useNavigate()
    const [checkFocus, setCheckFocus] = useState('')

    useEffect(() => {
        // isLoggedIn && navigate('/')
    },)

    const [payload, setPayload] = useState({
        canHaveGateways: false,
        displayName: '',
        maxDeviceCount: 0,
        maxGatewayCount: 0,
        name: '',
        email: value
    });

    const handleSubmit = async () => {
        // console.log(payload)
        await postData.postNewOrg(payload)
            .then(async (val) => {
                console.log(val)
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': val.data.msg, 'error': false, 'orgID': val.data.data.id }
                })
                // const res = await getOrgData.orgList(token)
                // if (res?.data.err === 0) {
                //     if (res?.data.msg === "Get data successfully") {
                //         if (res.data.data.length !== 0) {
                //             setValue(previousState => {
                //                 return { ...previousState, 'orgID': res.data.data[0].organizationID }
                //             })
                //         }
                //     } else {
                //         setValue(previousState => {
                //             return { ...previousState, 'orgID': res.data.data.result[0].id }
                //         })
                //     }
                // }
                navigate(-1)
            })
            .catch((err) => {
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': err.response.data.msg, 'error': true }
                })
            })
    }


    const handleClick = (e) => {
        setCheckFocus(e.target.id)
        if (e.target.id === '1') {
            setPayload(pre => {
                return { ...pre, 'maxDeviceCount': 5 }
            })
        } else if (e.target.id === '2') {
            setPayload(pre => {
                return { ...pre, 'maxDeviceCount': 10 }
            })
        } else if (e.target.id === '3') {
            setPayload(pre => {
                return { ...pre, 'maxDeviceCount': 100 }
            })
        }
    }

    const handleCheck = () => {
        setPayload(pre => {
            return { ...pre, 'canHaveGateways': !payload.canHaveGateways }
        })

    }
    const handleDouble = () => {
        setPayload(pre => {
            return { ...pre, 'maxDeviceCount': 0, 'name': '', 'displayName': '' }
        })
        setCheckFocus('')
    }
    // console.log(orgPayload)
    var list = [
        {
            id: '1',
            serviceName: 'Miễn phí',
            numberDevice: 5,
        },
        {
            id: '2',
            serviceName: 'Tiêu chuẩn',
            numberDevice: 10,
        },
        {
            id: '3',
            serviceName: 'Cao cấp',
            numberDevice: 100,
        }
    ]

    return (
        <div className='w-full max-w-[800px] h-full '>
            <div className='mb-[30px] text-xl font-medium text-textColor inline-block align-text-bottom'>Tạo Organization</div>
            <div className='border-2 border-solid py-[30px] px-[10px] rounded-md'>
                {/* <div className='grid grid-cols-3 gap-4 my-[20px]'>
                    {
                        list.map(item => {
                            return (
                                <div key={item.id} className='text-white flex justify-center'>
                                    <div
                                        id={item.id}
                                        value={item.numberDevice}
                                        className={`w-[300px] ${item.id !== checkFocus ? 'bg-secondaryColor' : 'bg-textColor'} border-solid rounded-[10px] border-2 border-blue-300 px-[10px]`}
                                        onDoubleClick={handleDouble}
                                        onClick={e => handleClick(e)}
                                    >
                                        <p id={item.id} value={item.numberDevice} className='text-center font-semibold text-l'>{item.serviceName}</p>
                                        <p id={item.id} value={item.numberDevice}>- {item.numberDevice} thiết bị </p>
                                        <p id={item.id} value={item.numberDevice} >.....</p>
                                    </div>
                                </div>)
                        })
                    }
                </div> */}
                {/* <div className='text-gray-500 ml-[30px]'>(Chọn một trong 3 gói trên để tạo organization)</div>
                <div className='text-gray-500 ml-[30px]'>(Nhấn 2 lần để hủy gói đã chọn)</div> */}
                {
                    // payload.maxDeviceCount !== 0 &&
                    <div className='grid grid-cols-4 gap-4'>
                        <div className='col-start-2 col-end-3'>
                            <CheckBox label={'Can have gateway?'} checked={payload.canHaveGateways} onchange={handleCheck} />
                        </div>
                        <div className='col-start-2 col-end-3'>
                            <InputForm setInvalidFields={setInvalidFields} invalidFields={0} label={'Organization name'} value={payload.name} setValue={setPayload} type='name' />
                            <h3 className='text-red-600'>{errMSG.msg}</h3>
                        </div>
                        <div className='col-start-3 col-end-4'>
                            <InputForm setInvalidFields={setInvalidFields} invalidFields={0} label={'Display name'} value={payload.displayName} setValue={setPayload} type='displayName' />
                        </div>
                    </div>
                }
                <div className='mt-7 flex justify-center'>
                    <Button text={'Create'} bgColor='bg-secondaryColor' textColor='text-white' onClick={handleSubmit} />
                    {/* <Button text={'Bỏ qua=>'} bgColor='bg-blue-400' textColor='text-white' onClick={handleSkip} /> */}
                </div>
            </div>
        </div>
    )
}

export default CreateOrg