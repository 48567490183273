import React, { useState, useEffect } from 'react'
import { InputForm, CheckBox, Button, SelectInputForm } from '../../../components'
import { postData, getOrgData } from '../../../services'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RandomHex } from '../../../components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import icons from '../../../ultils/icons'

const { Refresh, Copy } = icons
const AddGateway = ({ value, setValue }) => {
    const { token } = useSelector(state => state.auth)
    const [invalidFields, setInvalidFields] = useState()
    const [nwList, setNwList] = useState()
    const [stringOrArray, setStringOrArray] = useState(false)
    const navigate = useNavigate()
    let options = []
    useEffect(() => {
        const fetchData = async () => {
            const res = await getOrgData.networkServer().catch(err => console.log(err))
            setNwList(res.data.data.result)
        }

        fetchData()
    }, [])
    for (let i = 0; i < nwList?.length; i++) {
        options = [{
            value: nwList[i].id,
            label: nwList[i].name
        }]
    }
    const [payload, setPayload] = useState({
        boards: {
            fineTimestampKey: "",
            fpgaID: ""
        }
        ,
        description: "",
        discoveryEnabled: false,
        gatewayProfileID: "",
        id: "",
        location: {
            accuracy: 0,
            altitude: 0,
            latitude: 0,
            longitude: 0,
            source: "UNKNOWN"
        },
        metadata: {},
        name: "",
        networkServerID: "",
        organizationID: value.orgID,
        tags: {},
        email: ""
    })

    const types = ['General', 'Tags', 'MetaData']
    const [active, setActive] = useState(types[0])
    const handleCodeC = (e) => {
        if (e.target.name === 'description') {
            setPayload(pre => {
                return { ...pre, 'description': e.target.value }
            })
        }
    }

    // const [value, setValue] = useState("");

    const handleCheckbox = (e) => {
        setPayload(pre => {
            return { ...pre, 'discoveryEnabled': !payload.discoveryEnabled }
        })
    }

    const handleOnchange = (e) => {
        setPayload(pre => {
            return { ...pre, 'location': { ...pre.location, 'altitude': e.target.value } }
        })
    }

    const postGateway = async (token, payload) => {
        await postData.postGateway(token, payload)
            .then((val) => {
                console.log(val)
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': val.data.msg, 'error': false }
                })
                navigate(-1)
            })
            .catch((err) => {
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': err.response.data.msg, 'error': true }
                })
            })
    }

    const hexSpace = (value) => {
        let inputVal = value?.replace(/ /g, ""); //remove all the empty spaces in the input
        // Get nd array of 4 digits per an element EX: ["42", "42", ...]
        const splits = inputVal?.match(/.{1,2}/g);
        let spacedNumber = "";
        if (splits) {
            spacedNumber = splits.join(" "); // Join all the splits with an empty space
        }
        // console.log(spacedNumber)
        return spacedNumber
    }
    const hexArray = (value) => {
        let inputVal = value?.replace(/0x/g, "").replace(/ /g, ""); //remove all the empty spaces in the input
        // Get nd array of 4 digits per an element EX: ["42", "42", ...]
        const splits = inputVal?.match(/.{1,2}/g);
        let hexArray = []
        if (splits) {
            splits.map((value) => {
                if (value !== '0x') {
                    hexArray.push(`0x${value}`)
                    return hexArray
                } else {
                    return hexArray.push(value)
                }
            })
        }
        // console.log(hexArray)
        return hexArray
    }
    const handleSubmit = async () => {
        await postGateway(token, payload)
        console.log(payload)
    }

    const onChange = e => {
        const input = e.currentTarget.value;
        // console.log('input', input.toString())
        if (!stringOrArray) {
            if (/^[0-9a-f_ ]+$/.test(input) || input === "") {
                // only allow hex and whitespace
                if (e.target.id === 'numeroSerie') {
                    setPayload(pre => {
                        return { ...pre, 'id': input.replace(/ /g, "") }
                    })
                }
            }
        } else {
            if (/^[0-9a-f_,x]+$/.test(input) || input === "") {
                // only allow hex and whitespace
                if (e.target.id === 'numeroSerie') {
                    setPayload(pre => {
                        return { ...pre, 'id': input.toString().replace(/0x/g, "").replace(/,/g, "") }
                    })
                }
            }
        }

    }

    const handleRefresh = (e) => {
        var hex
        if (e.currentTarget.id === 'numeroSerie') {
            hex = RandomHex.randomHexString(16)
            setPayload(pre => {
                return { ...pre, 'id': hex }
            })
        }
    }

    const handleCopy = (e) => {
        if (e.currentTarget.id === 'numeroSerie') {
            if (!stringOrArray) {
                navigator.clipboard.writeText(hexSpace(payload.id))
            } else {
                navigator.clipboard.writeText(hexArray(payload.id))
            }
        }
    }

    const changeStringOrArray = (e) => {
        if (e.target.id === 'numeroSerie') {
            setStringOrArray(!stringOrArray)
        }
        // setPayload(pre => {
        //     return { ...pre, 'devAddr': stringOrArray ? hexArray(payload.devAddr) : hexSpace(payload.devAddr) }
        // })
        // console.log(stringOrArray)
    }
    return (
        <div className='w-full h-full'>
            <div className='p-[10px] text-xl font-medium text-textColor inline-block align-text-bottom'>Add GateWay Profile</div>
            <div className='border px-[10px] py-[20px] text-textColor rounded-md'>
                <div className='flex flex-nowrap items-centers justify-center overflow:hidden '>
                    {types.map(type => (
                        <button
                            className={`${active === type ? 'bg-red-200' : 'bg-gray-200'} min-w-[200px] w-1/6`}
                            key={type}
                            active={active === type}
                            onClick={() => setActive(type)}
                        >
                            {type}
                        </button>
                    ))}
                </div>
                {
                    active === 'General' &&
                    <div>
                        <InputForm type='name' label={'Gateway Name'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.name} setValue={setPayload} />
                        <div className=' my-2'>
                            <h2 className='text-xs'>Gateway Description</h2>
                            <textarea
                                wrap='off'
                                className='w-full text-start border-2 outline-none rounded p-2 resize-none'
                                name='description'
                                rows={2}
                                value={payload.description}
                                onChange={e => handleCodeC(e)}

                            // defaultValue={'hello'}
                            >
                            </textarea>
                        </div>
                        <div>
                            <h2 className='text-xs'>Gateway ID</h2>
                            <div className='flex items-center'>
                                <input
                                    className='bg-blue-100 outline-none rounded p-1 w-full'
                                    id="numeroSerie"
                                    name="num_serie"
                                    minLength={stringOrArray ? 49 : 23}
                                    maxLength={stringOrArray ? 49 : 23}
                                    value={stringOrArray ? hexArray(payload.id) : hexSpace(payload.id)}
                                    onChange={onChange}
                                    placeholder={'Input at least 16 HEX character  '}
                                />
                                <div id="numeroSerie" className='mx-[10px] cursor-pointer' onClick={(e) => { changeStringOrArray(e) }}>{stringOrArray ? 'Arr' : 'Str'}</div>
                                <FontAwesomeIcon className='mx-[10px] cursor-pointer' id="numeroSerie" icon={Refresh} color={'gray-500'} onClick={(e) => handleRefresh(e)} />
                                <FontAwesomeIcon className='mx-[10px] cursor-pointer' id="numeroSerie" icon={Copy} color={'gray-500'} onClick={(e) => handleCopy(e)} />
                            </div>
                        </div>
                        <div className='my-[10px]'>
                            <h2 className='text-xs'>Network Server</h2>
                            <SelectInputForm options={options} val={'networkServerID'} setValue={setPayload} />
                        </div>
                        <CheckBox id={'discoveryEnabled'} label={'Discovery Enabled'} checked={payload.discoveryEnabled} onchange={e => handleCheckbox(e.target.id)} />
                        <InputForm typeKeyboard={'number'} label={'Gateway altitude(meters)'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.location.altitude} onchange={e => handleOnchange(e)} />
                        <InputForm type='email' label={'Email to receive status'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.email} setValue={setPayload} />
                    </div>
                }
                {
                    active === 'Tags' &&
                    <div>
                        Tags can be used to store additional key/value data.
                    </div>
                }
                {
                    active === 'MetaData' &&
                    <div>
                        Metadata can be used by the ChirpStack Gateway Bridge to push information about the gateway (e.g. ip / hostname, serial number, temperatures, ...). This information is automatically updated when gateway statistics are received.
                    </div>
                }
                <div className='flex justify-end my-2'>
                    <Button text={'Create Gateway'} onClick={handleSubmit} />
                </div>
            </div>
        </div>
    )
}

export default AddGateway