import React, { useState, useCallback } from 'react';
import { Collapse } from 'react-collapse';
import dateFormat from "dateformat";
import icons from '../../ultils/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const { Down, Up } = icons;

const Txack = ({ items, status }) => {
    // console.log('items is:', items)
    // console.log('status is:', status)
    const accessibilityIds = {
        checkbox: 'accessible-marker-example1',
        button: 'accessible-marker-example2'
    }
    const [isButtonCollapseOpen, setIsButtonCollapseOpen] = useState(false);
    // const [rxInfo, setRxInfo] = useState(false);
    // const [txInfo, setTxInfo] = useState(false);
    // const [rxItems, setRxItems] = useState(items.rxInfo.map((item, index) => {
    //     return ({
    //         main: false,
    //         location: false
    //     })
    // }));
    // console.log(rxItems)
    const onClick = useCallback(
        () => setIsButtonCollapseOpen(!isButtonCollapseOpen),
        [isButtonCollapseOpen]
    )
    // const onClickController = (e) => {
    //     if (e === 'rxInfo') {
    //         setRxInfo(!rxInfo)
    //     }
    //     if (e === 'txInfo') {
    //         setTxInfo(!txInfo)
    //     }
    //     // console.log(e)
    //     const newState = rxItems.map((obj, index) => {
    //         // 👇️ if id equals 2, update country property
    //         if (`rxItems[${index}]` === e) {
    //             console.log(obj)
    //             return { ...obj, 'main': !obj.main }
    //         }
    //         if (`location[${index}]` === e) {
    //             return { ...obj, 'location': !obj.location }
    //         }
    //         // 👇️ otherwise return the object as is
    //         return obj;
    //     });
    //     setRxItems(newState)
    // }
    return (
        <div>
            <div className='w-full bg-primaryColor text-textColor border-2 border-secondaryColor rounded-md my-[10px]'>
                <button
                    className='w-full flex p-[10px]'
                    aria-controls={accessibilityIds.button}
                    aria-expanded={isButtonCollapseOpen}
                    onClick={onClick}
                    type="button">
                    <div className='w-full flex space-x-5'>
                        {/* <div>{dateFormat(items.rxInfo[0]?.time, "h:MM:ss TT")}</div> */}
                        <div>{status}</div>
                    </div>
                    <div> {isButtonCollapseOpen ? <FontAwesomeIcon icon={Up} /> : <FontAwesomeIcon icon={Down} />}</div>
                </button>
                <div className='h-full px-[10px]'>
                    <Collapse
                        isOpened={isButtonCollapseOpen}>
                        <div id={accessibilityIds.button} className="h-full blob">
                            <div className='flex space-x-3'>
                                <div>
                                    applicationID:
                                </div>
                                <div>
                                    {items.applicationID}
                                </div>
                            </div>
                            <div className='flex space-x-3'>
                                <div>
                                    applicationName:
                                </div>
                                <div>
                                    {items.applicationName}
                                </div>
                            </div>
                            <div className='flex space-x-3'>
                                <div>
                                    deviceName:
                                </div>
                                <div>
                                    {items.deviceName}
                                </div>
                            </div>
                            <div className='flex space-x-3'>
                                <div>
                                    devEUI:
                                </div>
                                <div>
                                    {items.devEUI}
                                </div>
                            </div>
                            <div className='flex space-x-3'>
                                <div>
                                    fCnt:
                                </div>
                                <div>
                                    {items.fCnt}
                                </div>
                            </div>
                            <div className='flex space-x-3'>
                                <div>
                                    ackownle:
                                </div>
                                <div>
                                    {items.fCnt}
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>
    )
}


export default Txack