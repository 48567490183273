import React, { useState, useEffect } from 'react'
import { postData, getOrgData, deleteData } from '../../services'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '../Button'
import icons from '../../ultils/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from '../../ultils/colors'

const { secondaryColor } = colors
const { Refresh, Trash } = icons
const GetQueue = ({ value, setQueue }) => {
    const { devEUI } = useParams()
    const { token } = useSelector(state => state.auth)
    const handleSubmit = async () => {
        await deleteData.deleteDownlinkQueue(token, devEUI)
            .then(async (val) => {
                // console.log(val)
                // setValue(previousState => {
                //     return { ...previousState, 'close': true, 'message': 'Delete success', 'error': false }
                // })
                // navigate(-1)
                await getOrgData.getDownlinkQueue(token, devEUI).then((val) => setQueue(val.data.data.deviceQueueItems))
            })
            .catch((err) => {
                // setValue(previousState => {
                //     return { ...previousState, 'close': true, 'message': err.response.data.msg, 'error': true }
                // })
            })
    }
    // console.log(value.value)
    return (
        <div className='border-2 mx-[10px] my-[20px] p-5 overflow-hidden'>
            <div className='flex justify-between w-full text-textColor'>
                <strong>Downlink Queue</strong>
                <div className='flex items-center'>
                    <Button size={'w-[40px]'} id={'refresh'} icAfter={Refresh} iconColor={secondaryColor} />
                    <Button size={'w-[40px]'} id={'delete'} icAfter={Trash} iconColor={secondaryColor} onClick={handleSubmit} />
                </div>
            </div>
            <table table='true' className='w-full h=full' >
                <thead className='border-b-2 '>
                    <tr>
                        <th className='text-start text-textColor px-5 py-4'>FCnt</th>
                        <th className='text-start text-textColor px-5 py-4'>FPort</th>
                        <th className='text-start text-textColor px-5 py-4'>Confirmed</th>
                        <th className='text-start text-textColor px-5 py-4'>Base64 encoded payload</th>
                    </tr>
                </thead>
                {(value?.length > 0) && value.map((val, key) => {
                    return (
                        <tbody key={key} className='border-b-2 '>
                            <tr>
                                <td className='text-start px-5 py-2'>{val.fCnt}</td>
                                <td className='text-start px-5 py-2'>{val.fPort}</td>
                                <td className='text-start px-5 py-2'>{val.confirmed ? 'true' : 'false'}</td>
                                <td className='text-start px-5 py-2'>{val.data}</td>
                            </tr>
                        </tbody>
                    )
                })}
            </table>
        </div>

    )
}

export default GetQueue