import React, { useState, useEffect, useRef, useReducer } from 'react'
import { InputForm, CheckBox, Button, SelectInputForm, Cart, RandomHex, Txack, Enqueue, GetQueue } from '../../../components'
import { postData, getOrgData } from '../../../services'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import AddDevice from '../addScreens/addDevice'
import { CircleLoader, ClipLoader } from "react-spinners";
import { io } from "socket.io-client"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import icons from '../../../ultils/icons'

const { Refresh, Copy } = icons
const UpdateDevice = ({ value, setValue }) => {
    const { devEUI } = useParams()
    const types = ['Details', 'Configuration', 'Keys (OTAA)', 'Activation', 'Device Data', 'LoRa Frames', 'Firmware']
    const [invalidFields, setInvalidFields] = useState()
    const [active, setActive] = useState(types[0])
    const { id } = useParams()
    const navigate = useNavigate()
    const { token } = useSelector(state => state.auth)
    const [device, setDevice] = useState({})
    const [eventUp, setEventUp] = useState([])
    const [stringOrArray, setStringOrArray] = useState({
        dev: false,
        app: false,
        net: false
    })
    const [payload, setPayload] = useState({
        jwt: token,
        aFCntDown: 0,
        appSKey: '',
        devAddr: '',
        devEUI: devEUI,
        fCntUp: 0,
        fNwkSIntKey: '',
        nFCntDown: 0,
        nwkSEncKey: '',
        sNwkSIntKey: ''
    })

    const [jwt, setJwt] = useState({
        upLinkJwt: ''
    }
    )
    const [queue, setQueue] = useState()
    // 
    // // useEffect(() => {
    // //     
    // // }, [id, devEUI])

    // useEffect(() => {
    //     socket.current.emit("GetData", id, devEUI)
    //     socket.current.on("Return Data", val => {
    //         setEventUp(pre => [...pre, val]
    //         )
    //     })
    // }, [])
    const eventUpView = (eventUp) => {
        return (<div>
            {eventUp.length === 0 ? <ClipLoader color='#2497F3'></ClipLoader> : eventUp.slice(0).reverse().map((item, index) => (
                item.val.status === 'up'
                    ? <Cart key={index} items={item.val.payload} status={item.val.status} />
                    : item.val.status === 'txack'
                        ? <Txack key={index} items={item.val.payload} status={item.val.status} />
                        : item.val.status === 'ack'
                            ? <Txack key={index} items={item.val.payload} status={item.val.status} />
                            : item.val.status === 'error' && <div></div>
            ))}
        </div>)
    }
    useEffect(() => {
        eventUpView(eventUp)
    }, [eventUp])

    const downlinkQueue = (queue) => {
        return (<div>
            <GetQueue value={queue} setQueue={setQueue} />
        </div>)
    }
    console.log(eventUp)
    useEffect(() => {
        downlinkQueue(queue)
    }, [queue])

    useEffect(() => {
        const fetchData = async () => {
            const res = await getOrgData.deviceWithDevEUI(devEUI, token).catch(err => console.log(err))
            // console.log(res.data.data.device)
            setDevice(res.data.data.device)
        }
        const fetchJwt = async () => {
            const res = await getOrgData.getJwt(id, devEUI, value.infoJWT).catch(err => console.log(err))
            // console.log(res.data.data)
            setJwt({ 'upLinkJwt': res.data.data })
        }
        const fetchActive = async () => {
            await getOrgData.getActive(token, devEUI)
                .then((val) => {
                    console.log('then val')
                    setPayload(pre => {
                        return {
                            ...pre,
                            'devAddr': val?.data.data.deviceActivation.devAddr,
                            'aFCntDown': val?.data.data.deviceActivation.aFCntDown,
                            'appSKey': val?.data.data.deviceActivation.appSKey,
                            'devEUI': val?.data.data.deviceActivation.devEUI,
                            'fCntUp': val?.data.data.deviceActivation.fCntUp,
                            'fNwkSIntKey': val?.data.data.deviceActivation.fNwkSIntKey,
                            'nFCntDown': val?.data.data.deviceActivation.nFCntDown,
                            'nwkSEncKey': val?.data.data.deviceActivation.nwkSEncKey,
                            'sNwkSIntKey': val?.data.data.deviceActivation.sNwkSIntKey
                        }
                    })
                })
                .catch((err) => {
                })
        }
        const fetchQueue = async (token, devEUI) => {
            await getOrgData.getDownlinkQueue(token, devEUI)
                .then(val => setQueue(val.data.data.deviceQueueItems))
                .catch(err => console.log(err))
        }
        fetchJwt(id, devEUI)
        fetchData(devEUI, token)
        fetchActive(token, devEUI)
        fetchQueue(token, devEUI)
    }, [devEUI, id, token, value.infoJWT])
    // console.log(payload)

    const hexSpace = (value) => {
        let inputVal = value?.replace(/ /g, ""); //remove all the empty spaces in the input
        // Get nd array of 4 digits per an element EX: ["42", "42", ...]
        const splits = inputVal?.match(/.{1,2}/g);
        let spacedNumber = "";
        if (splits) {
            spacedNumber = splits.join(" "); // Join all the splits with an empty space
        }
        // console.log(spacedNumber)
        return spacedNumber
    }
    const hexArray = (value) => {
        let inputVal = value?.replace(/0x/g, "").replace(/ /g, ""); //remove all the empty spaces in the input
        // Get nd array of 4 digits per an element EX: ["42", "42", ...]
        const splits = inputVal?.match(/.{1,2}/g);
        let hexArray = []
        if (splits) {
            splits.map((value) => {
                if (value !== '0x') {
                    hexArray.push(`0x${value}`)
                    return hexArray
                } else {
                    return hexArray.push(value)
                }
            })
        }
        // console.log(hexArray)
        return hexArray
    }

    const onChange = e => {
        const input = e.currentTarget.value;
        // console.log('input', input.toString())
        if (!stringOrArray.dev) {
            if (/^[0-9a-f_ ]+$/.test(input) || input === "") {
                // only allow hex and whitespace
                if (e.target.id === 'devAddr') {
                    setPayload(pre => {
                        return { ...pre, 'devAddr': input.replace(/ /g, "") }
                    })
                }
            }
        } else {
            if (/^[0-9a-f_,x]+$/.test(input) || input === "") {
                // only allow hex and whitespace
                if (e.target.id === 'devAddr') {
                    setPayload(pre => {
                        return { ...pre, 'devAddr': input.toString().replace(/0x/g, "").replace(/,/g, "") }
                    })
                }
            }
        }
        if (!stringOrArray.app) {
            if (/^[0-9a-f_ ]+$/.test(input) || input === "") {
                // only allow hex and whitespace
                if (e.target.id === 'appSKey') {
                    setPayload(pre => {
                        return { ...pre, 'appSKey': input.toString().replace(/0x/g, "").replace(/,/g, "") }
                    })
                }
            } else {
                if (/^[0-9a-f_,x]+$/.test(input) || input === "") {
                    // only allow hex and whitespace
                    if (e.target.id === 'appSKey') {
                        setPayload(pre => {
                            return { ...pre, 'appSKey': input.toString().replace(/0x/g, "").replace(/,/g, "") }
                        })
                    }
                }
            }
        }
        if (!stringOrArray.app) {
            if (/^[0-9a-f_ ]+$/.test(input) || input === "") {
                // only allow hex and whitespace
                let temp = input.toString().replace(/0x/g, "").replace(/,/g, "")
                if (e.target.id === 'nwkSEncKey') {
                    setPayload(pre => {
                        return { ...pre, 'fNwkSIntKey': temp, 'nwkSEncKey': temp, 'sNwkSIntKey': temp }
                    })
                }
            } else {
                if (/^[0-9a-f_,x]+$/.test(input) || input === "") {
                    // only allow hex and whitespace
                    let temp = input.toString().replace(/0x/g, "").replace(/,/g, "")
                    if (e.target.id === 'nwkSEncKey') {
                        setPayload(pre => {
                            return { ...pre, 'fNwkSIntKey': temp, 'nwkSEncKey': temp, 'sNwkSIntKey': temp }
                        })
                    }
                }

            }
        }
    }
    const postActiveDevice = async (payload) => {
        await postData.postActiveDevice(token, payload).then((val) => {
            // console.log(val)
            setValue(previousState => {
                return { ...previousState, 'close': true, 'message': val.data.msg, 'error': false }
            })
            navigate(-1)
        })
            .catch((err) => {
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': err.response.data.msg, 'error': true }
                })
            })
        // console.log('res in addActiveDevice function', value)
    }
    useEffect(() => {
        // for local
        // const socket = io("ws://localhost:8900")
        // socket.on(`3/a2e5fee8982994c1`, val => {
        //     setEventUp(
        //         pre => [...pre, { val }]
        //     )
        //     // console.log(val)
        // });


        // for sever
        const socket = io("wss://45.117.83.198:8900")
        socket.on(`${id}/${devEUI}`, val => {
            setEventUp(
                pre => [...pre, { val }]
            )
            // console.log(val)
        });
        return () => socket.emit('end'); //close socket on unmount
    }, [id, devEUI])
    console.log(eventUp)
    const navigateBarController = (e) => {
        setActive(e)
        if (e === 'Device Data') {
            // socket.current.emit("GetData", id, devEUI)
            // socket.current.on(`3/627d2ec9cad817a2`, val => {
            //     setEventUp(
            //         pre => [...pre, val]
            //     )
            //     console.log(val)
            // })

        }
    }
    const handleSubmit = (e) => {
        if (e === 'active') {
            postActiveDevice(payload)
            // console.log(payload.devEUI)
        }
    }
    const handleRefresh = (e) => {
        var hex
        if (e.currentTarget.id === 'devAddr') {
            hex = RandomHex.randomHexString(8)
            setPayload(pre => {
                return { ...pre, 'devAddr': hex }
            })
        } else if (e.currentTarget.id === 'appSKey') {
            hex = RandomHex.randomHexString(32)
            // console.log(hex)
            setPayload(pre => {
                return { ...pre, 'appSKey': hex }
            })
        } else if (e.currentTarget.id === 'nwkSEncKey') {
            hex = RandomHex.randomHexString(32)
            // console.log(hex)
            setPayload(pre => {
                return { ...pre, 'fNwkSIntKey': hex, 'nwkSEncKey': hex, 'sNwkSIntKey': hex }
            })
        }
    }

    const handleCopy = (e) => {
        if (e.currentTarget.id === 'devAddr') {
            if (!stringOrArray.dev) {
                navigator.clipboard.writeText(hexSpace(payload.devAddr))
            } else {
                navigator.clipboard.writeText(hexArray(payload.devAddr))
            }
        } else if (e.currentTarget.id === 'appSKey') {
            if (!stringOrArray.app) {
                navigator.clipboard.writeText(hexSpace(payload.appSKey))
            } else {
                navigator.clipboard.writeText(hexArray(payload.appSKey))
            }
        } else if (e.currentTarget.id === 'nwkSEncKey') {
            if (!stringOrArray.net) {
                navigator.clipboard.writeText(hexSpace(payload.nwkSEncKey))
            } else {
                navigator.clipboard.writeText(hexArray(payload.nwkSEncKey))
            }
        }
    }

    const changeStringOrArray = (e) => {
        if (e.target.id === 'devAddr') {
            setStringOrArray(pre => {
                return { ...pre, 'dev': !stringOrArray.dev }
            })
        } else if (e.target.id === 'appSKey') {
            setStringOrArray(pre => {
                return { ...pre, 'app': !stringOrArray.app }
            })
        } else if (e.target.id === 'nwkSEncKey') {
            setStringOrArray(pre => {
                return { ...pre, 'net': !stringOrArray.net }
            })
        }
        // setPayload(pre => {
        //     return { ...pre, 'devAddr': stringOrArray ? hexArray(payload.devAddr) : hexSpace(payload.devAddr) }
        // })
        // console.log(stringOrArray)
    }
    // console.log(queue)
    return (
        <div className='w-full h-full px-5'>
            <div className='p-[10px] text-xl font-medium text-textColor inline-block align-text-bottom'>Device</div>
            <div className='flex'>
                {types.map(type => (
                    <button
                        className={`${active === type ? 'bg-red-200' : 'bg-gray-200'} w-1/6 `}
                        key={type}
                        active={active === type}
                        onClick={() => navigateBarController(type)}
                    >
                        {type}
                    </button>
                ))}
            </div>
            {
                active === 'Details' &&
                <div>
                    <div className='w-full grid grid-cols-2'>
                        <div className='border-2 mx-[10px] my-[20px] p-5'>
                            <strong>Details</strong>
                            <table className='w-full'>
                                <tbody>
                                    <tr>
                                        <td className='text-start px-5 py-2 border-b-2'>Name</td>
                                        <td className='text-start px-5 py-2 border-b-2'>{device.name}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start px-5 py-2 border-b-2'>Description</td>
                                        <td className='text-start px-5 py-2 border-b-2'>{device.description}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start px-5 py-2 border-b-2'>Device-profile</td>
                                        <td className='text-start px-5 py-2 border-b-2'>{device.deviceProfileID}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='border-2 mx-[10px] my-[20px] p-5 overflow-hidden'>
                            <strong>Status</strong>
                            <table className='w-full'>
                                <tbody>
                                    <tr>
                                        <td className='text-start px-5 py-2 border-b-2'>Last Seen</td>
                                        <td className='text-start px-5 py-2 border-b-2'>{device.lastSeenAt ? device.lastSeenAt : 'null'}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start px-5 py-2 border-b-2'>State</td>
                                        <td className='text-start px-5 py-2 border-b-2'>{device.isDisabled ? 'disable' : 'enable'}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start px-5 py-2 border-b-2'>JWT:</td>
                                        <td className='text-start px-5 py-2 border-b-2 break-all'>bearer {jwt.upLinkJwt}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start px-5 py-2 border-b-2'>Api Guide</td>
                                        <td className='text-start px-5 py-2 border-b-2 break-all'>
                                            <a
                                                rel="noreferrer noopener"
                                                target="_blank"
                                                href='https://docs.google.com/document/d/1o-Nkt-U07_2ivK0bYHiq5sG1kKswPiUM/edit?usp=sharing&ouid=110428145038089024245&rtpof=true&sd=true'
                                                class="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                                                https://docs.google.com/document/d/1o-Nkt-U07_2ivK0bYHiq5sG1kKswPiUM/edit?usp=sharing&ouid=110428145038089024245&rtpof=true&sd=true
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>
                    <div className='pb-[20px]'>
                        <Enqueue value={value} setValue={setValue} setQueue={setQueue} />
                    </div>
                    <div className='pb-[20px]'>
                        {downlinkQueue(queue)}
                    </div>
                </div>
            }
            {
                active === 'Configuration' &&
                // <AddDevice val={value} />
                <div>Configuration</div>
            }
            {
                active === 'Keys (OTAA)' &&
                <div>
                    Keys (OTAA)
                </div>
            }
            {
                active === 'Activation' &&
                <div className='border-2 rounded p-5 my-5'>
                    <div className='py-2'>
                        <h2 className='text-xs'>Device Address</h2>
                        <div className='flex items-center'>
                            <input
                                className='bg-blue-100 outline-none rounded p-1 w-full'
                                id="devAddr"
                                name="num_serie"
                                minLength={stringOrArray.dev ? 19 : 11}
                                maxLength={stringOrArray.dev ? 19 : 11}
                                value={stringOrArray.dev ? hexArray(payload.devAddr) : hexSpace(payload.devAddr)}
                                onChange={onChange}
                                placeholder={'Input at least 8 HEX character  '}

                            />
                            <div id="devAddr" className='mx-[10px] cursor-pointer' onClick={(e) => { changeStringOrArray(e) }}>{stringOrArray.dev ? 'Arr' : 'Str'}</div>
                            <FontAwesomeIcon className='mx-[10px] cursor-pointer' id="devAddr" icon={Refresh} color={'gray-500'} onClick={(e) => handleRefresh(e)} />
                            <FontAwesomeIcon className='mx-[10px] cursor-pointer' id="devAddr" icon={Copy} color={'gray-500'} onClick={(e) => handleCopy(e)} />
                        </div>

                    </div>
                    <div className='py-2'>
                        <h2 className='text-xs'>Network session key</h2>
                        <div className='flex items-center'>
                            <input
                                className='bg-blue-100 outline-none rounded p-1 w-full'
                                id="nwkSEncKey"
                                name="num_serie"
                                minLength={stringOrArray.net ? 63 : 47}
                                maxLength={stringOrArray.net ? 63 : 47}
                                value={stringOrArray.net ? hexArray(payload.nwkSEncKey) : hexSpace(payload.nwkSEncKey)}
                                onChange={onChange}
                                placeholder={'Input at least 32 HEX character  '}
                            />
                            <div id="nwkSEncKey" className='mx-[10px] cursor-pointer' onClick={(e) => { changeStringOrArray(e) }}>{stringOrArray.net ? 'Arr' : 'Str'}</div>
                            <FontAwesomeIcon className='mx-[10px] cursor-pointer' id="nwkSEncKey" icon={Refresh} color={'gray-500'} onClick={(e) => handleRefresh(e)} />
                            <FontAwesomeIcon className='mx-[10px] cursor-pointer' id="nwkSEncKey" icon={Copy} color={'gray-500'} onClick={(e) => handleCopy(e)} />
                        </div>
                    </div>
                    <div className='py-2'>
                        <h2 className='text-xs'>Application session key</h2>
                        <div className='flex items-center'>
                            <input
                                className='bg-blue-100 outline-none rounded p-1 w-full'
                                id="appSKey"
                                name="num_serie"
                                minLength={stringOrArray.app ? 63 : 47}
                                maxLength={stringOrArray.app ? 63 : 47}
                                value={stringOrArray.app ? hexArray(payload.appSKey) : hexSpace(payload.appSKey)}
                                onChange={onChange}
                                placeholder={'Input at least 32 HEX character  '}
                            />
                            <div id="appSKey" className='mx-[10px] cursor-pointer' onClick={(e) => { changeStringOrArray(e) }}>{stringOrArray.app ? 'Arr' : 'Str'}</div>
                            <FontAwesomeIcon className='mx-[10px] cursor-pointer' id="appSKey" icon={Refresh} color={'gray-500'} onClick={(e) => handleRefresh(e)} />
                            <FontAwesomeIcon className='mx-[10px] cursor-pointer' id="appSKey" icon={Copy} color={'gray-500'} onClick={(e) => handleCopy(e)} />
                        </div>
                    </div>
                    <InputForm typeKeyboard={'number'} type='fCntUp' label={'fCntUp'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.fCntUp} setValue={setPayload} />
                    <InputForm typeKeyboard={'number'} type='nFCntDown ' label={'nFCntDown '} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.nFCntDown} setValue={setPayload} />
                    {(value.adServer === true || value.adOrg === true || value.adDev === true)
                        && <div className='flex justify-end mt-[30px]'>
                            <Button
                                bgColor={'bg-secondaryColor'}
                                textColor={'text-white'}
                                id={'active'}
                                text={'(Re)Active device'}
                                onClick={e => handleSubmit(e.target.id)}
                            />
                        </div>
                    }
                </div>
            }
            {
                active === 'Device Data' &&
                <div className='flex justify-center mt-[20px]'>
                    <div className='w-full'>{eventUpView(eventUp)}</div>
                </div>
            }
            {
                active === 'LoRa Frames' &&
                <div>
                    LoRa Frames
                </div>
            }
            {
                active === 'Firmware' &&
                <div>
                    Firmware
                </div>
            }
        </div>
    )
}

export default UpdateDevice