import React, { useState } from 'react'
import { InputForm, CheckBox, Button } from '../../../components'
import { postData } from '../../../services'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
const AddKey = ({ value, setValue }) => {
    const [invalidFields, setInvalidFields] = useState([])
    const { token } = useSelector(state => state.auth)
    const [payload, setPayload] = useState(
        {
            name: '',
            orgId: value.orgID
        }
    )
    const navigate = useNavigate()
    const postOrgKey = async (token, payload) => {
        await postData.postOrgKey(token, payload)
            .then((val) => {
                console.log(val)
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': val.data.msg, 'error': false }
                })
                navigate(-1)
            }).catch((err) => {
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': err.response.data.msg, 'error': true }
                })
            })
        // console.log('res in addKey function', res)
    }
    const handleSubmit = () => {
        postOrgKey(token, payload)
        console.log('Org ID is:', payload.orgId)
    }
    return (
        <div className='h-full w-full max-w-[800px] p-[10px]'>
            <div className='p-[10px] text-xl font-medium text-textColor inline-block align-text-bottom'>Add Keys</div>
            <div className='border px-[10px] py-[20px] text-textColor'>
                <InputForm type='name' label={'Key name'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.name} setValue={setPayload} />
                <div className='flex justify-end mt-[20px]'>
                    <Button
                        bgColor={'bg-secondaryColor'}
                        textColor={'text-white'}
                        text={'Add new Key'}
                        hover={'hover:bg-blue-200'}
                        onClick={handleSubmit}
                    />
                </div>
            </div>
        </div>

    )
}

export default AddKey