import React from 'react'

const SmallButton = ({ onClick, id, text, }) => {
    return (
        <button
            type='button'
            className={`m-[5px] flex items-center justify-center rounded-sm w-[15px] h-[15px] bg-white hover:bg-gray-200 cursor-pointer`}
            onClick={onClick}
            id={id}
        >
            {text}
        </button>
    )
}

export default SmallButton