import React, { useState, useEffect } from 'react'
import { InputForm, CheckBox, Button, SelectInputForm } from '../../../components'
import { postData, getOrgData } from '../../../services'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const AddDeviceProfile = ({ value, setValue }) => {
    const { token } = useSelector(state => state.auth)
    const [invalidFields, setInvalidFields] = useState()
    const types = ['General', 'Join', 'ClassB', 'ClassC', 'CodeC', 'Tag']
    const navigate = useNavigate()
    const encode = `// Encode encodes the given object into an array of bytes.
//  - fPort contains the LoRaWAN fPort number
//  - obj is an object, e.g. {"temperature": 22.5}
//  - variables contains the device variables e.g. {"calibration": "3.5"} (both the key / value are of type string)
// The function must return an array of bytes, e.g. [225, 230, 255, 0]
function Encode(fPort, obj, variables) {
  return [];
    }`

    const decode = `// Decode decodes an array of bytes into an object.
//  - fPort contains the LoRaWAN fPort number
//  - bytes is an array of bytes, e.g. [225, 230, 255, 0]
//  - variables contains the device variables e.g. {"calibration": "3.5"} (both the key / value are of type string)
// The function must return an object, e.g. {"temperature": 22.5}
function Decode(fPort, bytes, variables) {
  return {};
    }`
    let options = []
    const [nwList, setNwList] = useState()
    useEffect(() => {
        const fetchData = async () => {
            const res = await getOrgData.networkServer().catch(err => console.log(err))
            setNwList(res.data.data.result)
        }

        fetchData()
    }, [])
    for (let i = 0; i < nwList?.length; i++) {
        options = [{
            value: nwList[i].id,
            label: nwList[i].name
        }]
    }

    const loraVer = [{
        value: '1.0.0',
        label: '1.0.0'
    },
    {
        value: '1.0.1',
        label: '1.0.1'
    },
    {
        value: '1.0.2',
        label: '1.0.2'
    },
    {
        value: '1.0.3',
        label: '1.0.3'
    },
    {
        value: '1.1.0',
        label: '1.1.0'
    }
    ]


    const regionVer = [
        {
            value: 'A',
            label: 'A'
        },
        {
            value: 'B',
            label: 'B'
        }
    ]

    const codeC = [
        {
            value: '',
            label: 'None'
        },
        {
            value: 'CAYENNE_LPP',
            label: 'Cayenne LPP'
        },
        {
            value: 'CUSTOM_JS',
            label: 'Custom JavaScript codec functions'
        }
    ]

    const pingSlot = [
        {
            value: '32',
            label: 'every second'
        },
        {
            value: '64',
            label: 'every 2 seconds'
        },
        {
            value: '128',
            label: 'every 4 seconds'
        },
        {
            value: '256',
            label: 'every 8 seconds'
        },
        {
            value: '512',
            label: 'every 16 seconds'
        },
        {
            value: '1024',
            label: 'every 32 seconds'
        },
        {
            value: '2048',
            label: 'every 64 seconds'
        },
        {
            value: '4096',
            label: 'every 128 seconds'
        },
    ]


    const [active, setActive] = useState(types[0]);
    const [payload, setPayload] = useState(
        {
            classBTimeout: 0,
            classCTimeout: 0,
            factoryPresetFreqs: "0",
            geolocBufferTTL: 0,
            geolocMinBufferSize: 0,
            id: "",
            macVersion: "",
            maxDutyCycle: 0,
            maxEIRP: 0,
            name: "",
            networkServerID: "1",
            payloadCodec: "",
            payloadDecoderScript: "",
            payloadEncoderScript: "",
            pingSlotDR: 0,
            pingSlotFreq: 0,
            pingSlotPeriod: 0,
            regParamsRevision: "",
            rfRegion: "",
            rxDROffset1: 0,
            rxDataRate2: 0,
            rxDelay1: 0,
            rxFreq2: 0,
            supports32BitFCnt: false,
            supportsClassB: false,
            supportsClassC: false,
            supportsJoin: false,
            tags: {},
            organizationID: value.orgID
        }
    )

    const handleCheckbox = (e) => {
        if (e === 'OTAA') {
            setPayload(pre => {
                return { ...pre, 'supportsJoin': !payload.supportsJoin }
            })
        } else if (e === 'ClassB') {
            setPayload(pre => {
                return { ...pre, 'supportsClassB': !payload.supportsClassB }
            })
        } else if (e === 'ClassC') {
            setPayload(pre => {
                return { ...pre, 'supportsClassC': !payload.supportsClassC }
            })
        }
    }

    const handleCodeC = (e) => {
        if (e.target.name === 'Decode') {
            setPayload(pre => {
                return { ...pre, 'payloadDecoderScript': e.target.value }
            })
        } else if (e.target.name === 'Encode') {
            setPayload(pre => {
                return { ...pre, 'payloadEncoderScript': e.target.value }
            })
        }
    }

    const handleOnclick = (e) => {
        // console.log(payload)
    }
    const postDevProfile = async (token, payload) => {
        await postData.postDeviceProfile(token, payload)
            .then((val) => {
                console.log(val)
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': val.data.msg, 'error': false }
                })
                navigate(-1)
            })
            .catch((err) => {
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': err.response.data.msg, 'error': true }
                })
            })
    }
    const handleSubmit = () => {
        postDevProfile(token, payload)
        console.log('Org ID is:', payload)
    }
    return (
        <div className='w-full h-full'>
            <div className='p-[10px] text-xl font-medium text-textColor inline-block align-text-bottom'>Add Device Profile</div>
            <div className='border px-[10px] py-[20px] text-textColor rounded-md'>
                <div className='flex flex-nowrap items-centers'>
                    {types.map(type => (
                        <button
                            className={`${active === type ? 'bg-red-200' : 'bg-gray-200'} min-w-[100px] w-1/6`}
                            key={type}
                            active={active === type}
                            onClick={() => setActive(type)}
                        >
                            {type}
                        </button>
                    ))}
                </div>
                {
                    active === 'General' && <div>
                        <InputForm type='name' label={'Device Profile Name'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.name} setValue={setPayload} />
                        <div className='my-[10px]'>
                            <h2 className='text-xs'>Network Server</h2>
                            <SelectInputForm options={options} val={'networkServerID'} setValue={setPayload} />
                        </div>
                        <div className='my-[10px]'>
                            <h2 className='text-xs'>macVersion</h2>
                            <SelectInputForm options={loraVer} val={'macVersion'} setValue={setPayload} />
                        </div>
                        <div className='my-[10px]'>
                            <h2 className='text-xs'>RegionVer</h2>
                            <SelectInputForm options={regionVer} val={'regParamsRevision'} setValue={setPayload} />
                        </div>
                        <InputForm typeKeyboard={'number'} type='maxEIRP' label={'maxEIRP'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.maxEIRP} setValue={setPayload} />
                    </div>
                }
                {
                    active === 'Join' && <div>
                        <CheckBox id={'OTAA'} label={'Device support OTAA'} checked={payload.supportsJoin} onchange={e => handleCheckbox(e.target.id)} />
                        {
                            !payload.supportsJoin &&
                            <div>
                                <InputForm typeKeyboard={'number'} type='rxDelay1' label={'rxDelay1'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.rxDelay1} setValue={setPayload} />
                                <InputForm typeKeyboard={'number'} type='rxDROffset1' label={'rxDROffset1'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.rxDROffset1} setValue={setPayload} />
                                <InputForm typeKeyboard={'number'} type='rxDataRate2' label={'rxDataRate2'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.rxDataRate2} setValue={setPayload} />
                                <InputForm typeKeyboard={'number'} type='rxFreq2' label={'rxFreq2'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.rxFreq2} setValue={setPayload} />
                                <InputForm type='factoryPresetFreqs' label={'factoryPresetFreqs'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.factoryPresetFreqs} setValue={setPayload} />
                            </div>
                        }
                    </div>
                }
                {
                    active === 'ClassB' && <div>
                        <CheckBox id={'ClassB'} label={'Device support ClassB'} checked={payload.supportsClassB} onchange={e => handleCheckbox(e.target.id)} />
                        {
                            payload.supportsClassB &&
                            <div>
                                <InputForm typeKeyboard={'number'} type='classBTimeout' label={'classBTimeout'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.classBTimeout} setValue={setPayload} />
                                <div className='my-[10px]'>
                                    <h2 className='text-xs'>macVersion</h2>
                                    <SelectInputForm options={pingSlot} val={'pingSlotPeriod'} setValue={setPayload} />
                                </div>
                                <InputForm typeKeyboard={'number'} type='pingSlotDR' label={'pingSlotDR'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.pingSlotDR} setValue={setPayload} />
                                <InputForm typeKeyboard={'number'} type='pingSlotFreq' label={'pingSlotFreq'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.pingSlotFreq} setValue={setPayload} />


                            </div>
                        }
                    </div>
                }
                {
                    active === 'ClassC' && <div>
                        <CheckBox id={'ClassC'} label={'Device support ClassC'} checked={payload.supportsClassC} onchange={e => handleCheckbox(e.target.id)} />
                        <InputForm typeKeyboard={'number'} type='classCTimeout' label={'classCTimeout'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.classCTimeout} setValue={setPayload} />
                    </div>
                }
                {
                    active === 'CodeC' && <div>
                        <div className='my-[10px]'>
                            <h2 className='text-xs'>Payload codeC</h2>
                            <SelectInputForm options={codeC} val={'payloadCodec'} setValue={setPayload} />
                        </div>
                        {payload.payloadCodec === 'CUSTOM_JS' &&
                            <div>
                                <div className='border-2 p-2 m-2 '>
                                    <textarea
                                        wrap='off'
                                        className='w-full text-start border-2 p-2'
                                        name='Decode'
                                        rows={14}
                                        value={payload.payloadDecoderScript}
                                        onChange={e => handleCodeC(e)}
                                        placeholder={decode}
                                    // defaultValue={'hello'}
                                    >
                                    </textarea>
                                    <div className='text-gray-500'>
                                        The function must have the signature
                                        <strong> function Decode(fPort, bytes) </strong>
                                        and must return an object. ChirpStack Application Server will convert this object to JSON.
                                    </div>
                                </div>
                                <div className='border-2 p-2 m-2'>
                                    <textarea
                                        className='w-full text-start border-2 p-2'
                                        wrap='off'
                                        name='Encode'
                                        rows={14}
                                        value={payload.payloadEncoderScript}
                                        onChange={e => handleCodeC(e)}
                                        placeholder={encode}
                                    >
                                    </textarea>
                                    <div className='text-gray-500'>
                                        The function must have the signature
                                        <strong> function Encode(fPort, obj) </strong>
                                        and must return an array of bytes.
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                {
                    active === 'Tag' &&
                    <div className=''>
                        <div> Tags can be used to store additional key/value data.</div>
                        <Button text={'Add Tags'} value={'123'} onClick={e => handleOnclick(e)} border='border-2'></Button>
                    </div>
                }
                <div className='flex justify-end'>
                    <Button text={'Create Device-profile'} onClick={handleSubmit} />
                </div>
            </div>
        </div>

    )
}

export default AddDeviceProfile