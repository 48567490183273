export const lastSeen = (t) => {
    let preTime = new Date(t)
    let time = new Date()
    var sub = time.getTime() / 1000 - preTime.getTime() / 1000
    if (sub < 60) {
        console.log(sub)
        let s = Math.floor(sub)
        return `in a few seconds ago`
    } else if (sub < 3600) {
        let m = Math.floor(sub / 60)
        let s = Math.floor(sub % 60)
        console.log(m, 'm', s, 's')
        return `${m} minutes ago`
    } else if (sub < 86400) {
        let h = Math.floor(sub / 3600)
        let m = Math.floor((sub - h * 3600) / 60)
        let s = Math.floor(sub % 60)
        console.log(h, 'h', m, 'm', s, 's')
        return `${h} hours ago`
    } else if (sub < 2592000) {
        let d = Math.floor(sub / 86400)
        let h = Math.floor((sub - d * 86400) / 3600)
        let m = Math.floor((sub - d * 86400 - h * 3600) / 60)
        let s = Math.floor(sub % 60)
        console.log(d, 'd', h, 'h', m, 'm', s, 's')
        return `${d} days ago`
    } else if (sub > 2592000) {
        let M = Math.round(sub / 2592000)
        console.log(M, 'month')
        return `${M} month ago`
    }
}
