import { Routes, Route, Router } from "react-router-dom"
import {
  Home,
  Login,
  OrgSetting,
  SignUp,
  OrgApiKey,
  OrgUser,
  Gateway,
  Application,
  Multicast,
  DeviceProfile,
  ServiceProfile,
  Device,
  AddUser,
  AddKey,
  CreateOrg,
  AddServiceProfile,
  AddDeviceProfile,
  AddGateway,
  AddApplication,
  AddDevice,
  AddMulticast,
  UpdateDevice,
  UpdateOrgUser,
  UpdateService,
  UpdateDeviceProfile,
  UpdateGateway
} from "./containers/Public";
// import { AddUser } from "./containers/Public";
import { path } from "./ultils/constain"
import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
function App() {
  const [value, setValue] = useState({
    sideBar: true,
    email: '',
    orgID: '',
    adServer: false,
    adOrg: false,
    adGateway: false,
    adDev: false,
    users: [],
    apiKeys: [],
    services: [],
    devices: [],
    gateways: [],
    applications: [],
    multicastGroup: [],
    close: false,
    error: false,
    message: '',
    infoJWT: ''
  })
  const { isLoggedIn } = useSelector(state => state.auth)


  return (
    <div className="h-screen bg-white">
      <Routes>
        <Route path={path.HOME} element={<Home value={value} setValue={setValue} />}>
          <Route path={path.LOGIN} element={<Login value={value} setValue={setValue} />} />
          <Route path={path.SIGNUP} element={<SignUp value={value} setValue={setValue} />} />
          {isLoggedIn &&
            <Route>
              <Route path={path.CREATE_ORG} element={<CreateOrg value={value.email} setValue={setValue} />} />
              <Route path={path.ORG_SETTING} element={<OrgSetting value={value.users} />} />
              <Route path={path.ORG_USER} element={<OrgUser value={value} setValue={setValue} />} />
              <Route path={path.ORG_API_KEY} element={<OrgApiKey value={value} setValue={setValue} />} />
              <Route path={path.SERVICE_PROFILE} element={<ServiceProfile value={value} setValue={setValue} />} />
              <Route path={path.DEVICE_PROFILE} element={<DeviceProfile value={value} setValue={setValue} />} />
              <Route path={path.GATEWAY} element={<Gateway value={value} setValue={setValue} />} />
              <Route path={path.APPLICATION} element={<Application value={value} setValue={setValue} />} />
              <Route path={path.MULTICAST_GROUP} element={<Multicast value={value} setValue={setValue} />} />
              <Route path={path.DEVICE} element={<Device Value={value} />} />
              <Route path={path.ADD_USER} element={<AddUser value={value} setValue={setValue} />} />
              <Route path={path.ADD_KEY} element={<AddKey value={value} setValue={setValue} />} />
              <Route path={path.ADD_SERVICE_PROFILE} element={<AddServiceProfile value={value} setValue={setValue} />} />
              <Route path={path.ADD_DEVICE_PROFILE} element={<AddDeviceProfile value={value} setValue={setValue} />} />
              <Route path={path.ADD_GATEWAY} element={<AddGateway value={value} setValue={setValue} />} />
              <Route path={path.ADD_APPLICATION} element={<AddApplication value={value} setValue={setValue} />} />
              <Route path={path.ADD_DEVICE} element={<AddDevice val={value} setValue={setValue} />} />
              <Route path={path.ADD_MULTICAST} element={<AddMulticast value={value.services} />} />
              <Route path={path.UPDATE_DEVICE} element={<UpdateDevice value={value} setValue={setValue} />} />
              <Route path={path.UPDATE_ORG_USER} element={<UpdateOrgUser value={value.orgID} setValue={setValue} />} />
              <Route path={path.UPDATE_SERVICE_PROFILE} element={<UpdateService value={value} setValue={setValue} />} />
              <Route path={path.UPDATE_DEVICE_PROFILE} element={<UpdateDeviceProfile value={value} setValue={setValue} />} />
              <Route path={path.UPDATE_GATEWAY} element={<UpdateGateway value={value} setValue={setValue} />} />
            </Route>
          }
        </Route>
      </Routes>
    </div>
  );
}

export default App;
