import React, { useCallback, useEffect, useState } from 'react'
import { Button, CusPagination, SelectInputForm } from '../../components'
import { path } from '../../ultils/constain'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from "react-router-dom";
import ReactPaginate from 'react-paginate'
import { getOrgData } from '../../services';
const OrgUser = ({ value, setValue }) => {
    const navigate = useNavigate();
    const { isLoggedIn, token } = useSelector(state => state.auth)
    const goAddUser = useCallback(() => {
        navigate('add-user')
        //navigate to path org-users/add-user
    })
    const handleSubmit = (e) => {
        navigate(`${value.orgID}/user/${e}`)
    }
    // console.log(value.users)
    useEffect(() => {
        const fetch = async () => {
            await getOrgData.orgUsers(value.orgID, token).then(val => setValue(previousState => {
                return { ...previousState, 'users': val.data.data.result }
            }))
        }
        fetch()
    }, [setValue, value.orgID, token])
    const [val, setVal] = useState({
        numberPerPage: 10,
        current: []
    })
    console.log(value.users)
    const list = [
        {
            label: '10',
            value: 10
        },
        {
            label: '25',
            value: 25
        },
        {
            label: '50',
            value: 50
        },
        {
            label: '100',
            value: 100
        },
        {
            label: '200',
            value: 200
        },
        {
            label: '500',
            value: 500
        }
    ]


    return (
        <div className='w-full max-w-[800px] h-full'>
            <div className='flex justify-between mb-[30px]'>
                <div className='text-xl text-textColor font-medium inline-block align-text-bottom'>Organization users</div>
                <Button text={'Add new user'} bgColor={'bg-secondaryColor'} textColor={'text-white'} onClick={goAddUser} hover={'hover:bg-hoverColor'} border={'border-solid border-2'} />
            </div>
            <div className='border-2 p-[10px]'>
                {value.users &&
                    <div className='overflow-hidden'>
                        <table table='true' className='w-full h-full' >
                            <thead className='border-b-2'>
                                <tr>
                                    <th className='text-start text-textColor px-5 py-4'>Email</th>
                                    <th className='text-start text-textColor px-5 py-4'>isAdmin</th>
                                    <th className='text-start text-textColor px-5 py-4'>isDeviceAdmin</th>
                                    <th className='text-start text-textColor px-5 py-4'>isGatewayAdmin</th>
                                </tr>
                            </thead>
                            {val.current.length > 0 && val.current.map((val, key) => {
                                return (
                                    <tbody className='border-b-2' key={key}>
                                        <tr>
                                            <td id={val.userID} className='text-start text-secondaryColor px-5 py-2 cursor-pointer' onClick={e => handleSubmit(e.target.id)}>{val.email}</td>
                                            <td className='text-start px-5 py-2'>{val.isAdmin ? 'true' : 'false'}</td>
                                            <td className='text-start px-5 py-2'>{val.isAdmin ? 'true' : val.isDeviceAdmin ? 'true' : 'false'}</td>
                                            <td className='text-start px-5 py-2'>{val.isAdmin ? 'true' : val.isGatewayAdmin ? 'true' : 'false'}</td>
                                        </tr>
                                    </tbody>

                                )
                            })}

                        </table>
                    </div>
                }
                {!value.users && <div>null</div>}
                <div className='flex justify-end overflow-visible'>
                    <div className='flex items-center'>
                        <div className='mr-[10px]'>Rows per page</div>
                        <SelectInputForm def={'10'} options={list} setValue={setVal} val={'numberPerPage'} />
                    </div>
                    <CusPagination val={val} value={value.users} setVal={setVal} />
                </div>
            </div>
        </div>
    )
}

export default OrgUser