import React, { useState, useEffect } from 'react'
import { InputForm, CheckBox, Button, SelectInputForm } from '../../../components'
import { postData, getOrgData } from '../../../services'
import { useSelector } from 'react-redux'

const AddMulticast = ({ value }) => {
    const { token } = useSelector(state => state.auth)
    const [payload, setPayload] = useState({
        dr: 0,
        fCnt: 0,
        frequency: 0,
        groupType: "CLASS_C",
        id: "",
        mcAddr: "",
        mcAppSKey: "",
        mcNwkSKey: "",
        name: "",
        pingSlotPeriod: "",
        serviceProfileID: ""
    })
    const [invalidFields, setInvalidFields] = useState()
    // useEffect(() => {
    //     const fetchData = async () => {
    //         const res = await getOrgData.().catch(err => console.log(err))
    //         console.log(res)
    //         setService(res.data.data.result)
    //     }

    //     fetchData()
    // }, [])
    let options = []
    for (let i = 0; i < value?.length; i++) {
        options[i] = {
            value: value[i].id,
            label: value[i].name
        }
    }

    const pingSlot = [
        {
            value: '32',
            label: 'every second'
        },
        {
            value: '64',
            label: 'every 2 seconds'
        },
        {
            value: '128',
            label: 'every 4 seconds'
        },
        {
            value: '256',
            label: 'every 8 seconds'
        },
        {
            value: '512',
            label: 'every 16 seconds'
        },
        {
            value: '1024',
            label: 'every 32 seconds'
        },
        {
            value: '2048',
            label: 'every 64 seconds'
        },
        {
            value: '4096',
            label: 'every 128 seconds'
        },
    ]
    const types = [{
        value: 'CLASS_B',
        label: 'Class-B'
    },
    {
        value: 'CLASS_C',
        label: 'Class-C'
    }
    ]
    const onChange = e => {
        const input = e.currentTarget.value;
        if (/^[0-9a-f]+$/.test(input) || input === "") {
            // check HEX input
            if (e.target.id === 'mcAddr') {
                setPayload(pre => {
                    return { ...pre, 'mcAddr': e.target.value }
                })
            } else if (e.target.id === 'mcAppSKey') {
                setPayload(pre => {
                    return { ...pre, 'mcAppSKey': e.target.value }
                })
            } else if (e.target.id === 'mcNwkSKey') {
                setPayload(pre => {
                    return { ...pre, 'mcNwkSKey': e.target.value }
                })
            }
        }
    }
    const postMulticast = async (token, payload) => {
        const res = await postData.postMulticast(token, payload)
        console.log('res in addGateway function', res)
    }
    const handleSubmit = async () => {
        await postMulticast(token, payload)
        // console.log(payload)
    }

    return (
        <div className='w-full '>
            <strong className='text-xl text-blue-400'>Multicast group create</strong>
            <div className='border-2 p-5 m-2 rounded'>
                <InputForm type='name' label={'Device Name'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.name} setValue={setPayload} />
                <div className='my-[10px]'>
                    <h2 className='text-xs'>Service Profiles</h2>
                    <SelectInputForm options={options} val={'serviceProfileID'} setValue={setPayload} />
                </div>
                <div className='py-2'>
                    <h2 className='text-xs'>Multicast Address</h2>
                    <input
                        className='bg-blue-100 outline-none rounded p-1 w-full'
                        id="mcAddr"
                        name="num_serie"
                        minLength={8}
                        maxLength={8}
                        value={payload.mcAddr}
                        onChange={onChange}
                        placeholder={'Input at least 16 HEX character  '}
                    />
                </div>
                <div className='py-2'>
                    <h2 className='text-xs'>Multicast network session key</h2>
                    <input
                        className='bg-blue-100 outline-none rounded p-1 w-full'
                        id="mcNwkSKey"
                        name="num_serie"
                        minLength={32}
                        maxLength={32}
                        value={payload.mcNwkSKey}
                        onChange={onChange}
                        placeholder={'Input at least 32 HEX character  '}
                    />
                </div>
                <div className='py-2'>
                    <h2 className='text-xs'>Multicast application session key</h2>
                    <input
                        className='bg-blue-100 outline-none rounded p-1 w-full'
                        id="mcAppSKey"
                        name="num_serie"
                        minLength={32}
                        maxLength={32}
                        value={payload.mcAppSKey}
                        onChange={onChange}
                        placeholder={'Input at least 32 HEX character  '}
                    />
                </div>
                <InputForm typeKeyboard={'number'} label={'Frame-counter'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.fCnt} setValue={setPayload} />
                <InputForm typeKeyboard={'number'} label={'Data-rate'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.dr} setValue={setPayload} />
                <InputForm typeKeyboard={'number'} label={'Frequency'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.frequency} setValue={setPayload} />
                <div className='my-[10px]'>
                    <h2 className='text-xs'>Multicast-group Type</h2>
                    <SelectInputForm options={types} val={'groupType'} setValue={setPayload} />
                </div>
                {
                    payload.groupType === 'CLASS_B' &&
                    <div>
                        <div className='my-[10px]'>
                            <h2 className='text-xs'>Class-B ping-slot periodicity </h2>
                            <SelectInputForm reverse={'top'} options={pingSlot} val={'pingSlotPeriod'} setValue={setPayload} />
                        </div>
                    </div>
                }
                <div className='flex justify-end my-5'>
                    <Button text={'Create Gateway'} onClick={handleSubmit} />
                </div>
            </div>
        </div>
    )
}

export default AddMulticast