import React, { useState, useEffect, useRef } from 'react'
import { InputForm, Button, CheckBox, PopupCustom } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../store/actions'
import { useNavigate } from 'react-router-dom'
import CreateOrg from './CreateOrg'
import { colors } from '../../ultils/colors'


const { secondaryColor } = colors
const SignUp = ({ value, setValue }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [invalidFields, setInvalidFields] = useState([])
    const { isLoggedIn, token } = useSelector(state => state.auth)
    const [payload, setPayload] = useState({
        email: '',
        password: '',
        phone: '',
    });

    const [errMSG, setErrMSG] = useState({
        msg: ''
    });

    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            switch (item[0]) {
                case 'password':
                    if (item[1].length < 6) {
                        setInvalidFields(prev => [...prev, {
                            name: item[0],
                            message: 'Mật khẩu phải lớn hơn 6 kí tự'
                        }])
                        invalids++
                    } else if (item[1] === '') {
                        setInvalidFields(prev => [...prev, {
                            name: item[0],
                            message: 'Mật khẩu không được bỏ trống'
                        }])
                        invalids++
                    }
                    break;
                case 'email':
                    if (item[1] === '') {
                        setInvalidFields(prev => [...prev, {
                            name: item[0],
                            message: 'Email không được bỏ trống'
                        }])
                        invalids++
                    }
                    break;
                case 'phone':
                    if (item[1] === '') {
                        setInvalidFields(prev => [...prev, {
                            name: item[0],
                            message: 'Số điện thoại không được bỏ trống'
                        }])
                        invalids++
                    }
                    break;
                default:
                    break;
            }
        })
        return invalids
    }


    const handleSubmit = async () => {
        const invalid = validate(payload)
        if (invalid === 0) await dispatch(actions.register(payload))
            .then((val) => {
                setErrMSG(prv => ({ ...prv, 'msg': val.data.msg }))
                console.log(val)
                if (val.type === 'REGISTER_SUCCESS') {
                    setValue(previousState => {
                        return { ...previousState, 'close': true, 'message': 'SignUp success' }
                    })
                    navigate('/')
                    dispatch(actions.login({ email: payload.email, password: payload.password })).then((val) => {
                        console.log(val)
                        // if (val.type === 'LOGIN_SUCCESS') {
                        //     navigate('/create-org')
                        // }
                    })
                }
            })
        setValue(previousState => {
            return { ...previousState, 'email': payload.email }
        })
        // console.log(token)
    }
    const [hidden, setHidden] = useState(true)
    const handleHidden = () => {
        setHidden(!hidden)
    }
    return (

        <div className='bg-primaryColor w-[800px] p-[30px] pb-[100px] '>
            <div className='border-2 rounded-md shadow-md p-[20px] text-textColor'>
                <h3 className='text-center font-semibold text-2xl m-[20px]'>Đăng Ký</h3>
                <div className='grid grid-cols-2 gap-4'>
                    <div className='col-start-1 col-end-2'>
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Email'}
                            value={payload.email}
                            setValue={setPayload}
                            type='email' />
                        <h3 className='text-red-600'>{errMSG.msg}</h3>
                    </div>
                    <div className='col-start-2 col-end-3'>
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Mật Khẩu'}
                            value={payload.password}
                            setValue={setPayload}
                            typeKeyboard={hidden && 'password'}
                            type='password' />
                    </div>
                    <CheckBox id={'hidden'} label={'Show password?'} checked={!hidden} onchange={e => handleHidden(e)} />
                    <div className='col-start-1 col-end-2'>
                        <InputForm
                            setInvalidFields={setInvalidFields}
                            invalidFields={invalidFields}
                            label={'Số điện thoại'}
                            value={payload.phone}
                            setValue={setPayload}
                            type='phone' />
                    </div>
                </div>
                <div className='mt-7 flex justify-center'>
                    <Button text={'Đăng Ký'} bgColor='bg-secondaryColor' textColor='text-white' onClick={handleSubmit} />
                </div>
                {/* <PopupCustom
                open={close}
                close={setClose}
                closeOnDocumentClick={false}
                content={'SignUp success'}
                contentStyle={{
                    width: "auto",
                    backgroundColor: `${secondaryColor}`,
                    border: "1px solid #e4e7ec",
                    boxShadow: "0px 10px 40px 10px rgb(0 0 0 / 10%)",
                    borderRadius: 20,
                    // make popup bottom right//
                    position: "absolute",
                    bottom: "10px",
                    right: "10px"
                }}
                overlayStyle={{
                    backgroundColor: "rgb(0 0 0 / 1%)"
                }}
            >

            </PopupCustom> */}
            </div>
        </div>
    )
}

export default SignUp