import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faRotateRight,
    faTrash,
    faChevronDown,
    faChevronUp,
    faArrowRightFromBracket,
    faArrowRightToBracket,
    faBars,
    faX,
    faBarsProgress,
    faTowerCell,
    faGear,
    faUsersGear,
    faUsers,
    faKey,
    faTabletScreenButton,
    faWifi,
    faIdCard,
    faCopy,
    faUserPlus
} from '@fortawesome/free-solid-svg-icons';

const icons = {
    LogOut: faArrowRightFromBracket,
    LogIn: faArrowRightToBracket,
    OpenMenu: faBars,
    CloseMenu: faX,
    GateWay: faTowerCell,
    OrgSetting: faUsersGear,
    OrgUsers: faUsers,
    OrgKey: faKey,
    ServiceProfile: faIdCard,
    DeviceProfile: faGear,
    Application: faTabletScreenButton,
    MultiGroup: faWifi,
    Up: faChevronUp,
    Down: faChevronDown,
    Trash: faTrash,
    Refresh: faRotateRight,
    Copy: faCopy,
    AddOrg: faUserPlus
}

export default icons