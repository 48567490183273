import React, { useState, useEffect } from 'react'
import { InputForm, CheckBox, Button } from '../../../components'
import { postData } from '../../../services'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
const AddUser = ({ value, setValue }) => {
    const [invalidFields, setInvalidFields] = useState([])
    const { token } = useSelector(state => state.auth)
    const navigate = useNavigate()
    const [payload, setPayload] = useState(
        {
            email: '',
            isAdmin: false,
            isDeviceAdmin: false,
            isGatewayAdmin: false,
            orgId: value.orgID
        }
    )



    const postOrgUser = async (token, payload) => {
        await postData.postOrgUser(token, payload)
            .then((val) => {
                console.log(val)
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': val.data.msg, 'error': false }
                })
                navigate(-1)
            })
            .catch((err) => {
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': err.response.data.msg, 'error': true }
                })
            })
        // console.log('res in addUser function', res)
    }
    // console.log(value)
    const handleAdmin = (e) => {
        if (e.currentTarget.id === 'isAdmin') {
            setPayload(pre => {
                return { ...pre, 'isAdmin': !payload.isAdmin }
            })
        } else if (e.currentTarget.id === 'isDeviceAdmin') {
            setPayload(pre => {
                return { ...pre, 'isDeviceAdmin': !payload.isDeviceAdmin }
            })
        } else if (e.currentTarget.id === 'isGatewayAdmin') {
            setPayload(pre => {
                return { ...pre, 'isGatewayAdmin': !payload.isGatewayAdmin }
            })
        }
    }
    const handleSubmit = () => {
        postOrgUser(token, payload)
        // console.log(token)
        // console.log('is org admin:', payload.isAdmin)
        // console.log('is device admin:', payload.isDeviceAdmin)
        // console.log('is gateways admin:', payload.isGatewayAdmin)
        // console.log('Email address is:', payload.email)
        // console.log('Org ID is:', payload.orgId)
    }
    return (
        <div className='h-full w-full max-w-[800px] p-[10px]'>
            <div className='text-xl font-medium text-textColor inline-block align-text-bottom p-[10px]'>Add User</div>
            <div className='border text-textColor px-[10px] py-[20px]'>
                <InputForm
                    type='email'
                    label={'Email'}
                    setInvalidFields={setInvalidFields}
                    invalidFields={0}
                    value={payload.email}
                    setValue={setPayload}
                />
                <CheckBox id={'isAdmin'} label={'Is Organization Admin?'} checked={payload.isAdmin} onchange={e => handleAdmin(e)} />
                {!payload.isAdmin &&
                    <div>
                        <CheckBox id={'isDeviceAdmin'} label={'Is Device Admin?'} checked={payload.isDeviceAdmin} onchange={e => handleAdmin(e)} />
                        <CheckBox id={'isGatewayAdmin'} label={'Is Gateway Admin?'} checked={payload.isGatewayAdmin} onchange={e => handleAdmin(e)} />
                    </div>}
                <div className='flex justify-end'>
                    <Button
                        bgColor={'bg-secondaryColor'}
                        textColor={'text-white'}
                        text={'Add new user'}
                        hover={'hover:bg-blue-200'}
                        onClick={handleSubmit}
                    />
                </div>
            </div>
        </div>

    )
}

export default AddUser