import React, { useState, useRef, useEffect } from 'react'
import { Button, CusPagination, SelectInputForm } from '../../components'
import { useNavigate } from 'react-router-dom'
import { io } from "socket.io-client"
import { getOrgData } from '../../services'
import { useDispatch, useSelector } from 'react-redux'
const Application = ({ value, setValue }) => {
    // const socket = useRef(io("ws://localhost:8900"))
    // useEffect(() => {
    //     socket.current.emit("GetData", 3, '627d2ec9cad817a2')
    //     socket.current.on('3/627d2ec9cad817a2', val => {
    //         console.log(val)
    //     })
    // }, [])
    const navigate = useNavigate()
    const { isLoggedIn, token } = useSelector(state => state.auth)
    const goAddApplication = () => {
        if (value.services.length === 0) {
            setValue(previousState => {
                return { ...previousState, 'close': true, 'message': 'Organization need to have service profile before adding gateway', 'error': true }
            })
        } else { navigate('add-application') }
    }
    useEffect(() => {
        const fetch = async () => {
            await getOrgData.orgApplications(value.orgID, token).then(val => setValue(previousState => {
                return { ...previousState, 'applications': val.data.data.result }
            }))
        }
        fetch()
    }, [setValue, value.orgID, token])
    const goApplication = (e) => {
        navigate(`${e}`)
        // console.log(e)
    }

    const [val, setVal] = useState({
        numberPerPage: 10,
        current: []
    })
    const list = [
        {
            label: '10',
            value: 10
        },
        {
            label: '25',
            value: 25
        },
        {
            label: '50',
            value: 50
        },
        {
            label: '100',
            value: 100
        },
        {
            label: '200',
            value: 200
        },
        {
            label: '500',
            value: 500
        }
    ]
    return (
        <div className='w-full max-w-[800px] h-full '>
            <div className='flex justify-between mb-[30px]'>
                <div className='text-xl font-medium text-textColor inline-block align-text-bottom'>Application</div>
                {(value.adServer === true || value.adOrg === true || value.adDev === true) &&
                    <div>
                        <Button
                            bgColor={'bg-secondaryColor'}
                            textColor={'text-white'}
                            text={'Create'}
                            onClick={goAddApplication}
                            hover={'hover:bg-blue-200'}
                            border={'border-solid border-2'}
                        />
                    </div>
                }
            </div >
            <div className='border-2 border-solid p-[10px]'>
                {value.applications &&
                    <table table='true' className='w-full h=full' >
                        <thead className='border-b-2 '>
                            <tr>
                                <th className='text-start text-textColor px-5 py-4'>ID</th>
                                <th className='text-start text-textColor px-5 py-4'>Name</th>
                                <th className='text-start text-textColor px-5 py-4'>Service-profiles</th>
                                <th className='text-start text-textColor px-5 py-4'>Descriptions</th>
                            </tr>
                        </thead>
                        {val.current.length > 0 && val.current.map((val, key) => {
                            return (
                                <tbody key={key} className='border-b-2 '>
                                    <tr>
                                        <td className='text-start px-5 py-2'>{val.id}</td>
                                        <td id={val.id} className='text-start px-5 py-2 text-secondaryColor cursor-pointer' onClick={e => goApplication(e.target.id)}>{val.name}</td>
                                        <td className='text-start px-5 py-2'>{val.serviceProfileName}</td>
                                        <td className='text-start px-5 py-2'>{val.description}</td>
                                    </tr>
                                </tbody>
                            )
                        })}
                    </table>
                }
                {!value.applications && <div>null</div>}
                <div className='flex justify-end overflow-visible'>
                    <div className='flex items-center'>
                        <div className='mr-[10px]'>Rows per page</div>
                        <SelectInputForm def={'10'} options={list} setValue={setVal} val={'numberPerPage'} />
                    </div>
                    <CusPagination val={val} value={value.applications} setVal={setVal} />
                </div>
            </div>


        </div>

    )
}

export default Application