import React, { useCallback, useEffect, useState } from 'react'
import logo from '../../asset/galaxy-logo-removebg-preview.png'
import { Button, IconButton } from '../../components'
import { useNavigate } from 'react-router-dom'
import { path } from '../../ultils/constain'
import { useDispatch, useSelector } from 'react-redux'
import icons from '../../ultils/icons'
import { colors } from '../../ultils/colors'
import * as actions from '../../store/actions'

const { LogOut, LogIn, OpenMenu, CloseMenu } = icons;
const { secondaryColor } = colors;

const Header = ({ value, setValue }) => {
    const navigate = useNavigate();
    const goLogin = useCallback(() => {
        navigate(path.LOGIN)
    })
    const { isLoggedIn } = useSelector(state => state.auth)
    useEffect(() => {
        !isLoggedIn && navigate('/')
    }, [isLoggedIn])
    const dispatch = useDispatch()


    const handleSubmit = async () => {
        setValue(previousState => {
            return { ...previousState, 'sideBar': !value.sideBar }
        })

        console.log(value)
    }
    const logout = async () => {
        dispatch(actions.logout()).then((val) => {
            if (val.type === 'LOGOUT') {
                setValue({
                    sideBar: true,
                    email: '',
                    orgID: '',
                    users: [],
                    apiKeys: [],
                    services: [],
                    devices: [],
                    gateways: [],
                    applications: [],
                    multicastGroup: [],
                    'close': false,
                    'message': '',
                    'error': false
                }
                )

            }
        })
    }
    console.log(window.location.pathname)
    const handleClick = () => {
        console.log('clicked')
        if (window.location.pathname !== '/') {
            navigate('/')
        } else {
            navigate(0)
        }
    }
    return (
        <div className={`w-full h-full flex items-center justify-between m-auto `}>
            <div className={`flex`}>
                {/* {isLoggedIn &&
                    <div className='flex items-end'>
                        <Button size={'w-[50px]'} icAfter={OpenMenu} iconColor={value.sideBar && secondaryColor} minWidth={false} onClick={handleSubmit} />
                    </div>} */}
                <div className='flex items-center mx-[10px]'>
                    <img src={logo} alt="logo" className='h-[100px] object-cover' />
                    {/* <h3>Công ty VN GALAXY</h3> */}
                </div>
            </div>
            <div className='flex items-center'>
                <div className={`font-medium mx-[20px] text-textColor text-lg cursor-pointer`} >
                    <Button text={'Home'} onClick={() => handleClick()} />
                </div>
                <div className={`font-medium mx-[20px] text-textColor text-lg`}>
                    Document
                </div>
            </div>
            <div className='flex items-center gap-1 mx-[10px]'>
                {!isLoggedIn &&
                    <div className='flex items-center gap-1 mx-[10px]'>
                        <Button text={"Đăng nhập"} textColor='text-white' bgColor={`bg-secondaryColor`} onClick={() => goLogin()} minWidth='200px' />
                        <Button text={"Đăng kí"} textColor='text-white' bgColor={`bg-secondaryColor`} onClick={() => navigate(path.SIGNUP)} minWidth='200px' />
                    </div>}
                {isLoggedIn &&
                    <div className='flex items-center'>
                        <h3>{value.email}</h3>
                        <Button icAfter={LogOut} iconColor={secondaryColor} minWidth={true} onClick={() => logout()} hover={'hover:underline hover:bg-blue-500'} />
                    </div>}
            </div>
        </div>
    )
}

export default Header