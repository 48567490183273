const actionTypes = {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAIL: 'REGISTER_FAIL',
    ADD_ORG_SUCCESS: 'ADD_ORG_SUCCESS',
    ADD_ORG_SKIP: 'ADD_ORG_SKIP',
    LOGOUT: 'LOGOUT'
}

export default actionTypes