import React, { useState, useEffect } from 'react'
import { InputForm, CheckBox, Button, SelectInputForm } from '../../../components'
import { postData, getOrgData, putData, deleteData } from '../../../services'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

const UpdateOrgUser = ({ value, setValue }) => {
    const { userID } = useParams()
    const { id } = useParams()
    const { token } = useSelector(state => state.auth)
    const navigate = useNavigate()
    const [invalidFields, setInvalidFields] = useState()
    const [payload, setPayload] = useState({
        createdAt: "2023-03-29T07:00:56.465Z",
        organizationUser: {
            email: "",
            isAdmin: true,
            isDeviceAdmin: true,
            isGatewayAdmin: true,
            organizationID: id,
            userID: userID
        },
        updatedAt: "2023-03-29T07:00:56.465Z"
    })
    const [error, setError] = useState(false)
    useEffect(() => {
        const fetchUserWithID = async () => {
            const res = await getOrgData.userWithID(userID, value, token)
                .catch((err) => {
                    setValue(previousState => {
                        return { ...previousState, 'close': true, 'message': err.response.data.msg, 'error': true }
                    })
                })
            if (res?.data.err === 0) {
                if (res.data.data.length !== 0) {
                    setPayload(res.data.data)
                }
            }
            console.log(res)
        }
        fetchUserWithID(userID, value, token)
    }, [token, userID, value, setValue])

    // console.log(payload)
    const handleOnchange = (e) => {
        setPayload(pre => {
            return { ...pre, 'organizationUser': { ...pre.organizationUser, 'email': e.target.value } }
        })
    }
    const handleAdmin = (e) => {
        if (e.target.id === 'org') {
            setPayload(pre => {
                return { ...pre, 'organizationUser': { ...pre.organizationUser, 'isAdmin': !payload.organizationUser.isAdmin, 'isDeviceAdmin': !payload.organizationUser.isDeviceAdmin, 'isGatewayAdmin': !payload.organizationUser.isGatewayAdmin, } }
            })
        } else if (e.target.id === 'device') {
            setPayload(pre => {
                return { ...pre, 'organizationUser': { ...pre.organizationUser, 'isDeviceAdmin': !payload.organizationUser.isDeviceAdmin } }
            })
        } else if (e.target.id === 'gateway') {
            setPayload(pre => {
                return { ...pre, 'organizationUser': { ...pre.organizationUser, 'isGatewayAdmin': !payload.organizationUser.isGatewayAdmin } }
            })
        }
    }
    const updateUser = async (token, payload) => {
        const val = {
            id: userID,
            orgID: id,
            isAdmin: payload.organizationUser.isAdmin,
            isDeviceAdmin: payload.organizationUser.isDeviceAdmin,
            isGatewayAdmin: payload.organizationUser.isGatewayAdmin
        }
        await putData.putUserWithID(token, val)
            .then((val) => {
                console.log(val)
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': val.data.msg, 'error': false }
                })
                navigate(-1)
            })
            .catch((err) => {
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': err.response.data.msg, 'error': true }
                })
            })
    }
    const deleteUser = async () => {
        await deleteData.deleteUserInOrg(token, userID, id)
            .then((val) => {
                console.log(val)
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': val.data.msg, 'error': false }
                })
                navigate(-1)
            })
            .catch((err) => {
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': err.response.data.msg, 'error': true }
                })
            })
        // console.log('res in addActiveDevice function', res)
    }
    const handleSubmit = async (e) => {
        if (e === 'update') {
            console.log('update')
            await updateUser(token, payload)
        }
        if (e === 'delete') {
            console.log('delete')
            await deleteUser()
        }

        // console.log(payload)
    }
    return (
        <div className='w-full px-5'>
            {error === false && <div>
                <div className='flex justify-between'>
                    <strong className='text-start text-xl text-textColor'>UpdateOrgUser</strong>
                    <div>
                        <Button id={'delete'} textColor={'text-red-600'} border={'border-2 border-red-400'} text={'Delete'} hover={'hover:bg-red-50'} onClick={e => handleSubmit(e.target.id)} />
                    </div>
                </div>
                <div className='w-full border-2 p-5 mt-[30px] text-textColor'>
                    <InputForm type='email' label={'Email'} readOnly={true} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.organizationUser.email} onchange={e => handleOnchange(e)} />
                    <CheckBox id={'org'} label={'Is Organization Admin?'} checked={payload.organizationUser.isAdmin} onchange={e => handleAdmin(e)} />
                    {!payload.organizationUser.isAdmin &&
                        <div>
                            <CheckBox id={'device'} label={'Is Device Admin?'} checked={payload.organizationUser.isDeviceAdmin} onchange={e => handleAdmin(e)} />
                            <CheckBox id={'gateway'} label={'Is Gateway Admin?'} checked={payload.organizationUser.isGatewayAdmin} onchange={e => handleAdmin(e)} />
                        </div>}
                    <div className='flex justify-end'>
                        <Button bgColor={'bg-secondaryColor'} textColor={'text-white'} id={'update'} text={'Update'} hover={'hover:bg-blue-200'} onClick={e => handleSubmit(e.target.id)} />
                    </div>
                </div></div>}
        </div>
    )
}

export default UpdateOrgUser