import axiosConfig from '../axiosConfig'

export const apiLogin = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            method: 'POST',
            url: '/api/auth/login',
            data: JSON.stringify(payload)
        })
        resolve(response)
    } catch (error) {
        reject(error)
    }
})

export const apiRegister = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'POST',
            url: '/api/auth/register',
            data: JSON.stringify(payload)
        })
        resolve(response)
    } catch (error) {
        reject(error)
    }
})