export const path = {
    HOME: '/*',
    LOGIN: 'login',
    SIGNUP: 'signup',
    CREATE_ORG: 'create-org',
    ORG_SETTING: 'org-setting',
    ORG_USER: 'org-users',
    ORG_API_KEY: 'org-api-keys',
    GATEWAY: 'gateways',
    APPLICATION: 'applications',
    SERVICE_PROFILE: 'service-profiles',
    DEVICE_PROFILE: 'device-profiles',
    MULTICAST_GROUP: 'multicast-group',
    DEVICE: 'applications/:id',
    ADD_USER: 'org-users/add-user',
    ADD_KEY: 'org-api-keys/add-key',
    ADD_SERVICE_PROFILE: 'service-profiles/add-service',
    ADD_DEVICE_PROFILE: 'device-profiles/add-device-profile',
    ADD_GATEWAY: 'gateways/add-gateway',
    ADD_APPLICATION: 'applications/add-application',
    ADD_DEVICE: 'applications/:id/add-device',
    ADD_MULTICAST: 'multicast-group/add-multicast',
    UPDATE_DEVICE: 'applications/:id/device/:devEUI',
    UPDATE_ORG_USER: 'org-users/:id/user/:userID',
    UPDATE_SERVICE_PROFILE: 'service-profiles/:ID',
    UPDATE_DEVICE_PROFILE: 'device-profiles/:ID',
    UPDATE_GATEWAY: 'gateways/:ID'
}