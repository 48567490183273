import React, { useState, useEffect } from 'react'
import { InputForm, CheckBox, Button, PopupCustom } from '../../../components'
import { putData, getOrgData, deleteData } from '../../../services'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

const UpdateServiceProfile = ({ value, setValue }) => {
    const { ID } = useParams()
    const { token } = useSelector(state => state.auth)
    const [invalidFields, setInvalidFields] = useState()
    const navigate = useNavigate()
    const [payload, setPayload] = useState(
        {
            name: "",
            addGWMetaData: false,
            nwkGeoLoc: false,
            devStatusReqFreq: 0,
            drMin: 0,
            drMax: 0
        }
    )
    const [response, setResponse] = useState(false)
    const [error, setError] = useState({
        status: '',
        error: '',
        code: '',
        message: '',
        details: ''
    })
    useEffect(() => {
        const fetchUserWithID = async () => {
            const res = await getOrgData.serviceProfileWithID(ID, token).catch(err => console.log(err))
            if (res.data.data.length !== 0) {
                setPayload(res.data.data.serviceProfile)
            }
            console.log(res)
        }
        fetchUserWithID(ID, token)
    }, [ID, token])
    const handleCheckBox = (e) => {
        if (e === 'geo') {
            setPayload(pre => {
                return { ...pre, 'nwkGeoLoc': !payload.nwkGeoLoc }
            })
        } else {
            setPayload(pre => {
                return { ...pre, 'addGWMetaData': !payload.addGWMetaData }
            })
        }
    }

    const updateService = async (payload) => {
        const val = {
            ID: ID,
            addGWMetaData: payload.addGWMetaData,
            devStatusReqFreq: payload.devStatusReqFreq,
            drMax: payload.drMax,
            drMin: payload.drMin,
            name: payload.name,
            nwkGeoLoc: payload.nwkGeoLoc,
        }
        // console.log(val)
        await putData.putServiceProfileWithID(token, val).then((val) => {
            console.log(val)
            setValue(previousState => {
                return { ...previousState, 'close': true, 'message': val.data.msg, 'error': false }
            })
            navigate(-1)
        })
            .catch((err) => {
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': err.response.data.msg, 'error': true }
                })
            })
    }
    const deleteService = async (ID, token) => {
        const res = await deleteData.deleteServiceProfile(ID, token).catch(err => console.log(err))
        // console.log(Object.keys(res.data.data).length === 0)
        if (Object.keys(res.data.data).length !== 0) {
            ///////////////////////check if object res have value then do something //////////////////////////
            // setError(prev => ({ ...prev, 'data':res.data.data }))
            console.log(JSON.parse(res.data.data))
            setError({
                status: true,
                error: JSON.parse(res.data.data).error,
                message: JSON.parse(res.data.data).message,
                code: JSON.parse(res.data.data).code,
                details: JSON.parse(res.data.data).details
            })
            throw new Error(true)
        } else {
            throw new Error(false)
        }

        // console.log('res in update function', res)
    }
    const handleOnclick = async () => {
        await updateService(payload)
    }
    const handleSubmit = async (e) => {
        setResponse(true)
        if (e === 'accept') {
            // console.log(e)
            await deleteService(ID, token).catch(err => {
                if (err.message === 'false') {
                    navigate(-1)
                }
            })
            setResponse(!response)
        }
    }
    console.log(error)
    return (
        <div className='h-full w-full p-[10px]'>
            <PopupCustom
                open={response}
                cancel={setResponse}
                accept={handleSubmit}
                overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
                contentStyle={{ width: '300px', backgroundColor: 'white', border: '' }}
                content={'Bạn có chắc chắn muốn xóa Service-profile này không?'}
                closeOnDocumentClick={false}
            />
            <div className='flex justify-between'>
                <strong className='text-start text-xl text-textColor'>UpdateServiceProfile</strong>
                <div>
                    <Button id={'delete'} textColor={'text-red-600'} border={'border-2 border-red-400'} text={'Delete'} hover={'hover:bg-red-50'} onClick={e => handleSubmit(e.target.id)} />
                </div>
            </div>
            <div className='w-full text-textColor border-2 p-5 mt-[30px]'>
                <InputForm type='name' label={'Service Profile Name'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.name} setValue={setPayload} />
                <CheckBox id={'meta'} label={'Add gateway meta-data'} checked={payload.addGWMetaData} onchange={e => handleCheckBox(e.target.id)} />
                <CheckBox id={'geo'} label={'Enable network geolocation'} checked={payload.nwkGeoLoc} onchange={e => handleCheckBox(e.target.id)} />
                <InputForm typeKeyboard={'number'} type='devStatusReqFreq' label={'Device-status request frequency'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.devStatusReqFreq} setValue={setPayload} />
                <InputForm typeKeyboard={'number'} type='drMin' label={'Minimum allow data-rate'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.drMin} setValue={setPayload} />
                <InputForm typeKeyboard={'number'} type='drMax' label={'Maximum allow data-rate'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.drMax} setValue={setPayload} />

                <div className='flex items-end justify-end mt-[30px]'>
                    <Button bgColor={'bg-secondaryColor'} text={'Update'} textColor={'text-white text-l'} onClick={handleOnclick} />
                </div>
            </div>
            <PopupCustom
                open={error.status}
                overlayStyle={{ placeItems: 'end', justifyItems: 'bottom' }}
                contentStyle={{ width: '500px', backgroundColor: 'gray', position: 'fixed', margin: 20 }}
                content={`${error.error}  (code:${error.code})`}
                close={setError}
                closeOnDocumentClick={true}
            />
        </div>
    )
}

export default UpdateServiceProfile