import React, { useState, useEffect } from 'react'
import { InputForm, CheckBox, Button, SelectInputForm } from '../../../components'
import { postData, getOrgData } from '../../../services'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const AddApplication = ({ value, setValue }) => {
    const { token } = useSelector(state => state.auth)
    const [invalidFields, setInvalidFields] = useState()
    const [spList, setSpList] = useState()
    const navigate = useNavigate()
    useEffect(() => {
        const fetchData = async () => {
            await getOrgData.orgServices(value.orgID, token)
                .then((val) => { setSpList(val.data.data.result) })
                .catch(err => console.log(err))
        }

        fetchData()
    }, [token, value.orgID])
    let options = []
    for (let i = 0; i < spList?.length; i++) {
        options[i] = {
            value: spList[i].id,
            label: spList[i].name
        }
    }
    const [payload, setPayload] = useState({
        description: "",
        id: "",
        name: "",
        organizationID: value.orgID,
        payloadCodec: "",
        payloadDecoderScript: "",
        payloadEncoderScript: "",
        serviceProfileID: ""
    })

    const postApplication = async (token, payload) => {
        await postData.postApplication(token, payload)
            .then((val) => {
                console.log(val)
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': val.data.msg, 'error': false }
                })
                navigate(-1)
            })
            .catch((err) => {
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': err.response.data.msg, 'error': true }
                })
            })
    }
    const handleSubmit = async () => {
        await postApplication(token, payload)
        console.log(payload)
    }
    return (
        <div className='w-full h-full'>
            <div className='p-[10px] text-xl font-medium text-textColor inline-block align-text-bottom'>Add Application</div>
            <div className='border px-[10px] py-[20px] text-textColor rounded-md'>
                <InputForm type='name' label={'Application Name'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.name} setValue={setPayload} />
                <InputForm type='description' label={'Application description'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.description} setValue={setPayload} />
                <div className='my-[10px]'>
                    <h2 className='text-xs'>Service Profile</h2>
                    <SelectInputForm options={options} val={'serviceProfileID'} setValue={setPayload} />
                </div>
                <div className='flex justify-end'>
                    <Button bgColor={'bg-secondaryColor'} textColor={'text-white'} text={'Create Application'} onClick={handleSubmit} />
                </div>
            </div>
        </div>
    )
}

export default AddApplication