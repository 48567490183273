import axiosConfig from '../axiosConfig'


export const networkServer = (payload) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            jwt: payload
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'GET',
            params: data,
            url: '/api/user/nw-server',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})


export const userProfile = (payload) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            jwt: payload
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'GET',
            params: data,
            url: '/api/user/profile',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})

export const orgList = (payload) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            jwt: payload
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'GET',
            params: data,
            url: '/api/user/list-org',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})


export const orgUsers = (id, jwt) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            id: id,
            jwt: jwt
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'GET',
            params: data,
            url: '/api/user/organization/users',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})


export const orgApiKeys = (id, jwt) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            id: id,
            jwt: jwt
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'GET',
            params: data,
            url: '/api/user/organization/keys',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})

export const orgServices = (id, jwt) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            id: id,
            jwt: jwt
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'GET',
            params: data,
            url: '/api/user/organization/services',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})
export const orgDevices = (id, jwt) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            id: id,
            jwt: jwt
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'GET',
            params: data,
            url: '/api/user/organization/devices',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})
export const orgApplications = (id, jwt) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            id: id,
            jwt: jwt
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'GET',
            params: data,
            url: '/api/user/organization/applications',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})
export const orgGateways = (id, jwt) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            id: id,
            jwt: jwt
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'GET',
            params: data,
            url: '/api/user/organization/gateways',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})
export const orgMulticastGroup = (id, jwt) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            id: id,
            jwt: jwt
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'GET',
            params: data,
            url: '/api/user/organization/multicast',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})

export const device = (id, jwt) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            id: id,
            jwt: jwt
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'GET',
            params: data,
            url: '/api/user/device',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})

export const deviceWithDevEUI = (id, jwt) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            id: id,
            jwt: jwt
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'GET',
            params: data,
            url: '/api/user/device/dev-eui',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})

export const userWithID = (id, orgID, jwt) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            id: id,
            orgID: orgID,
            jwt: jwt
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'GET',
            params: data,
            url: '/api/user/profile/user',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})


export const serviceProfileWithID = (ID, jwt) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            ID: ID,
            jwt: jwt
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'GET',
            params: data,
            url: '/api/user/organization/services/id',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})

export const deviceProfileWithID = (ID, jwt) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            ID: ID,
            jwt: jwt
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'GET',
            params: data,
            url: '/api/user/organization/devices/id',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})
export const gatewayDetailWithID = (ID, jwt) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            ID: ID,
            jwt: jwt
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'GET',
            params: data,
            url: '/api/user/organization/gateways/details',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})
export const gatewayStatusFrame = (jwt, ID, startTimestamp, endTimestamp) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            jwt: jwt,
            ID: ID,
            startTimestamp: startTimestamp,
            endTimestamp: endTimestamp
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'GET',
            params: data,
            url: '/api/user/organization/gateways/details/status',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})


export const getJwt = (appID, devEUI, infoJWT) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            appID: appID,
            devEUI: devEUI,
            infoJWT: infoJWT
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'GET',
            params: data,
            url: '/api/user/jwt',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})


export const getActive = (jwt, devEUI) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            jwt: jwt,
            devEUI: devEUI
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'GET',
            params: data,
            url: '/api/user/organization/devices/id/active',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})

export const getAllGateway = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'GET',
            url: '/api/user/all-gateway',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})

export const getDownlinkQueue = (jwt, devEUI) => new Promise(async (resolve, reject) => {
    const data = {
        devEUI: devEUI
    }
    try {
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                "Authorization": `Bearer ${jwt}`,
            },
            method: 'GET',
            url: '/api/user/queue',
            params: data,
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})