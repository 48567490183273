import React, { useState, useEffect } from 'react'
import { Button, SelectInputForm, CusPagination, LastSeen } from '../../components'
import { postData, getOrgData } from '../../services'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

const Device = ({ Value }) => {
    const { id } = useParams()
    const { token } = useSelector(state => state.auth)
    const types = ['Devices', 'Application Configuration', 'Integrations', 'FUOTA']
    const [value, setValue] = useState([])
    const [active, setActive] = useState(types[0])
    const navigate = useNavigate()
    const goAddDevice = () => {
        navigate('add-device')
    }
    useEffect(() => {
        const fetchData = async () => {
            const res = await getOrgData.device(id, token).catch(err => console.log(err))
            setValue(res.data.data.result)
        }
        fetchData()
    }, [id, token])
    const handleOnclick = (e) => {
        navigate(`device/${e}`)
    }
    const [val, setVal] = useState({
        numberPerPage: 10,
        current: []
    })
    const list = [
        {
            label: '10',
            value: 10
        },
        {
            label: '25',
            value: 25
        },
        {
            label: '50',
            value: 50
        },
        {
            label: '100',
            value: 100
        },
        {
            label: '200',
            value: 200
        },
        {
            label: '500',
            value: 500
        }
    ]
    return (
        <div className='w-full'>
            <div className='text-xl font-medium text-textColor inline-block align-text-bottom'>Device List</div>
            <div className='border-2 border-solid p-[10px] mt-[20px]'>
                <div className='flex'>
                    {types.map(type => (
                        <button
                            className={`${active === type ? 'bg-textColor' : 'bg-secondaryColor'} w-1/4`}
                            key={type}
                            active={active === type}
                            onClick={() => setActive(type)}
                        >
                            {type}
                        </button>
                    ))}
                </div>
                {
                    active === 'Devices' &&
                    <div>
                        {(Value.adServer === true || Value.adOrg === true || Value.adDev === true)
                            && <div className='flex justify-end mb-[30px] mt-[20px]'>
                                <Button
                                    bgColor={'bg-secondaryColor'}
                                    textColor={'text-white'}
                                    text={'Create'}
                                    onClick={goAddDevice}
                                    hover={'hover:bg-blue-200'}
                                    border={'border-solid border-2'} />
                            </div >}
                        <div className='border-2 p-[10px]'>
                            {value &&
                                <table table='true' className='w-full h=full' >
                                    <thead className='border-b-2'>
                                        <tr>
                                            <th className='text-textColor text-start px-5 py-4'>Last seen</th>
                                            <th className='text-textColor text-start px-5 py-4'>Device Name</th>
                                            <th className='text-textColor text-start px-5 py-4'>Device EUI</th>
                                            <th className='text-textColor text-start px-5 py-4'>Device profile</th>
                                            <th className='text-textColor text-start px-5 py-4'>Link margin</th>
                                            <th className='text-textColor text-start px-5 py-4'>Battery</th>
                                        </tr>
                                    </thead>
                                    {val.current.length > 0 && val.current.map((val, key) => {
                                        return (
                                            <tbody className='border-b-2' key={key}>
                                                <tr>
                                                    <td className='text-start px-5 py-2'>{val.lastSeenAt ? LastSeen.lastSeen(val.lastSeenAt) : 'null'}</td>
                                                    <td id={val.devEUI} className='text-start text-secondaryColor px-5 py-2 cursor-pointer' onClick={e => handleOnclick(e.target.id)}>{val.name}</td>
                                                    <td className='text-start px-5 py-2'>{val.devEUI}</td>
                                                    <td className='text-start px-5 py-2'>{val.deviceProfileName}</td>
                                                    <td className='text-start px-5 py-2'>n/a</td>
                                                    <td className='text-start px-5 py-2'>n/a</td>
                                                </tr>
                                            </tbody>
                                        )
                                    })}
                                </table>
                            }
                            {!value && <div>null</div>}
                            <div className='flex justify-end overflow-visible'>
                                <div className='flex items-center'>
                                    <div className='mr-[10px]'>Rows per page</div>
                                    <SelectInputForm options={list} setValue={setVal} val={'numberPerPage'} def={'10'} />
                                </div>
                                <CusPagination val={val} value={value} setVal={setVal} />
                            </div>
                        </div>
                    </div>
                }
                {
                    active === 'Application Configuration' &&
                    <div>
                        Application Configuration
                    </div>
                }
                {
                    active === 'Integrations' &&
                    <div>
                        Integrations
                    </div>
                }
                {
                    active === 'FUOTA' &&
                    <div>
                        FUOTA
                    </div>
                }
            </div>
        </div>
    )
}

export default Device