import React, { useEffect, useState } from 'react'
import { MapContainer, TileLayer, Popup, Marker } from 'react-leaflet'
import MarkerClusterGroup from "react-leaflet-cluster"
import "leaflet/dist/leaflet.css"
import { getOrgData } from '../../services'
import { Icon } from 'leaflet'
const GatewayMap = ({ value, setValue }) => {
    const position = [16.075805112035606, 108.15340688934236]
    const customIcon = new Icon({
        iconUrl: "https://developers.mydevices.com/wp-content/uploads/2016/10/lora_black_icon.png",
        iconSize: [50, 50]
    })
    const [gateway, setGateway] = useState([])
    useEffect(() => {
        const fetchGateway = async () => {
            await getOrgData.getAllGateway()
                .then((val) => {
                    // let res = val.data.data.result
                    setGateway(val.data.data.result)
                    // console.log(val.data.data.result)
                })
                .catch(err => console.log(err))
        }
        fetchGateway()
    }, [])
    console.log(gateway)
    return (
        <div className='border-2 h-full w-full'>
            <MapContainer style={{ height: "100%", width: "100%" }} center={position} zoom={13} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <MarkerClusterGroup>
                    {gateway.length > 0 && gateway.map((items, index) => {
                        return <div>
                            <Marker icon={customIcon} position={[items.location.latitude, items.location.longitude]}>
                                <Popup>
                                    GateWay name: {items.name}
                                </Popup>
                            </Marker>
                        </div>
                    })}
                </MarkerClusterGroup>
            </MapContainer>
        </div>
    )
}

export default GatewayMap