import rootReducer from "./store/reducers/rootReducer";
import { persistStore } from "redux-persist"
import { createStore, applyMiddleware } from "redux";
import thunk from 'redux-thunk'


const reduxStore = () => {
    const store = createStore(rootReducer, applyMiddleware(thunk))  // chưa có middleware để làm bất đồng bộ cho api
    const persist = persistStore(store)
    return { store, persist }
}

export default reduxStore