import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, IconButton } from '../../components'
import icons from '../../ultils/icons'

const { OpenMenu, CloseMenu } = icons;
const Navigation = ({ value, setValue }) => {
    const handleSubmit = async () => {
        setValue(previousState => {
            return { ...previousState, 'sideBar': !value }
        })

        console.log(value)
    }
    const { isLoggedIn } = useSelector(state => state.auth)
    return (
        <div className='w-full m-auto flex justify-start items-center h-full bg-blue-400 text-white'>
            {isLoggedIn &&
                <div className='flex items-center'>
                    <Button icAfter={OpenMenu} minWidth={false} onClick={handleSubmit} />
                </div>}
            <div className=''>
                Navigation Bar
            </div>

        </div>
    )
}

export default Navigation