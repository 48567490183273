import React from 'react'

const CheckBox = ({ label, checked, onchange, id, disable }) => {
    return (
        <div className="flex justify-start my-[20px]">
            <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                <input
                    className="relative
                    float-left mt-[0.15rem]
                    mr-[6px] -ml-[1.4rem] 
                    h-[1.125rem] 
                    w-[1.125rem] 
                    appearance-none 
                    rounded-[0.25rem] 
                    border-[0.125rem] 
                    border-solid 
                    border-neutral-300 
                    dark:border-neutral-600 
                    outline-none 
                    before:pointer-events-none 
                    cursor-none 
                    before:absolute 
                    before:h-[0.875rem] 
                    before:w-[0.875rem] 
                    before:scale-0 
                    before:rounded-full 
                    before:bg-transparent 
                    before:opacity-0 
                    before:shadow-[0px_0px_0px_13px_transparent] 
                    before:content-[''] 
                    checked:border-primary 
                    dark:checked:border-primary 
                    checked:bg-primary 
                    dark:checked:bg-primary 
                    checked:before:opacity-[0.16] 
                    checked:after:absolute 
                    checked:after:ml-[0.25rem] 
                    checked:after:-mt-px 
                    checked:after:block 
                    checked:after:h-[0.8125rem] 
                    checked:after:w-[0.375rem] 
                    checked:after:rotate-45 
                    checked:after:border-[0.125rem] 
                    checked:after:border-t-0 
                    checked:after:border-l-0 
                    checked:after:border-solid 
                    checked:after:border-black 
                    checked:after:bg-transparent 
                    checked:after:content-[''] 
                    indeterminate:border-primary 
                    dark:indeterminate:border-primary 
                    indeterminate:bg-primary 
                    dark:indeterminate:bg-primary 
                    indeterminate:after:absolute 
                    indeterminate:after:ml-[0.2rem] 
                    indeterminate:after:mt-[6px] 
                    indeterminate:after:w-[0.5rem] 
                    indeterminate:after:border-[0.125rem] 
                    indeterminate:after:border-t-0 
                    indeterminate:after:border-l-0 
                    indeterminate:after:border-solid 
                    indeterminate:after:border-white 
                    hover:cursor-pointer 
                    hover:before:opacity-[0.04] 
                    hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                    "
                    type="checkbox"
                    value=""
                    disabled={disable ? disable : false}
                    id={id ? id : 'flexCheckIndeterminate'}
                    checked={checked}
                    onChange={onchange}
                />
                <label
                    className="inline-block pl-[0.15rem] hover:cursor-pointer"
                >
                    {label}
                </label>
            </div>
        </div>
    )
}

export default CheckBox