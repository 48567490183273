import React, { useState, useEffect } from 'react'
import { Button, InputForm, CheckBox } from '../../../components'
import { getOrgData, putData, postData } from '../../../services'
import { useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import dateFormat from 'dateformat';
import moment from "moment";
import 'moment-timezone';
import { Line } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';

// import Chart from 'chart.js/auto';
const UpdateGateway = ({ value, setValue }) => {
    Chart.register(CategoryScale, LinearScale, PointElement, LineElement);
    const { ID } = useParams()
    const { token } = useSelector(state => state.auth)
    const types = ['Gateway Details', 'Gateway Configuration', 'Certificate', 'Gateway Discovery', 'Live LoRa Frames']
    const types2 = ['General', 'Tags', 'MetaData']
    const [active, setActive] = useState(types[0])
    const [active2, setActive2] = useState(types2[0])
    const [invalidFields, setInvalidFields] = useState()
    const [email, setEmail] = useState()
    const navigate = useNavigate()
    const [payload, setPayload] = useState({
        gateway: {
            id: "",
            name: "",
            description: "",
            location: {
                latitude: 0,
                longitude: 0,
                altitude: 0,
                source: "",
                accuracy: 0
            },
            organizationID: "",
            discoveryEnabled: false,
            networkServerID: "",
            gatewayProfileID: "",
            boards: [],
            tags: {},
            metadata: {}
        },
        createdAt: "",
        updatedAt: "",
        firstSeenAt: "",
        lastSeenAt: ""
    })
    const [frame, setFrame] = useState([
        {
            timestamp: "",
            rxPacketsReceived: 0,
            rxPacketsReceivedOK: 0,
            txPacketsReceived: 0,
            txPacketsEmitted: 0
        },
    ])
    useEffect(() => {
        // const now = moment();
        // const ago = moment().subtract(30, 'days')
        // ////////////////////// chuyen mui gio utc+7 sang +0 va dinh dang ISO 8601 /////////////////////////////////
        // let localTimeNow = moment.utc(now).tz('Iceland').toISOString();
        // let localTimeAgo = moment.utc(ago).tz('Iceland').toISOString();
        // console.log(localTimeNow)
        // console.log(localTimeAgo)
        const fetchDataWithID = async () => {
            const now = moment();
            const ago = moment().subtract(30, 'days')
            ////////////////////// chuyen mui gio utc+7 sang +0 va dinh dang ISO 8601 /////////////////////////////////
            let localTimeNow = moment.utc(now).tz('Iceland').toISOString();
            let localTimeAgo = moment.utc(ago).tz('Iceland').toISOString();
            const res = await getOrgData.gatewayStatusFrame(token, ID, localTimeAgo, localTimeNow).catch(err => console.log(err))
            if (res.data.data.length !== 0) {
                setFrame(res.data.data.result)
            }
            console.log(res)
        }
        fetchDataWithID(token, ID)
    }, [token, ID])

    useEffect(() => {
        const fetchDataWithID = async () => {
            const res = await getOrgData.gatewayDetailWithID(ID, token).catch(err => console.log(err))
            if (res.data.data.length !== 0) {
                setPayload(res.data.data)
                setEmail(res.data.email)
            }
            console.log(res)
        }
        fetchDataWithID(ID, token)
    }, [ID, token])


    const handleSubmit = async (e) => {
        if (e === 'delete') {
            console.log('delete')
        } else if (e === 'update') {
            // console.log(payload)
            // await putData.putGatewayDetail({
            //     token: token,
            //     id: payload.gateway.id,
            //     name: payload.gateway.name,
            //     boards: payload.gateway.boards,
            //     description: payload.gateway.description,
            //     discoveryEnabled: payload.gateway.discoveryEnabled,
            //     location: payload.gateway.location,
            //     metadata: payload.gateway.metadata,
            //     tags: payload.gateway.tags
            // })
            await postData.postEmail(payload.gateway.id, email).then((val) => {
                console.log(val)
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': val.data.msg, 'error': false }
                })
                navigate(-1)
            })
                .catch((err) => {
                    setValue(previousState => {
                        return { ...previousState, 'close': true, 'message': err.response.data.msg, 'error': true }
                    })
                })
        }
    }

    const handleOnchange = (e) => {
        if (e.target.id === 'altitude') {
            setPayload(pre => {
                return { ...pre, 'gateway': { ...pre.gateway, 'location': { ...pre.location, 'altitude': e.target.value } } }
            })
        } else if (e.target.id === 'name') {
            setPayload(pre => {
                return { ...pre, 'gateway': { ...pre.gateway, 'name': e.target.value } }
            })
        } else if (e.target.id === 'discoveryEnabled') {
            setPayload(pre => {
                return { ...pre, 'gateway': { ...pre.gateway, 'discoveryEnabled': e.target.checked } }
            })
        } else if (e.target.id === 'email') {
            setEmail(e.target.value)
        } else if (e.target.name === 'description') {
            setPayload(pre => {
                return { ...pre, 'gateway': { ...pre.gateway, 'description': e.target.value } }
            })
        }

    }

    return (
        <div className='w-full h-full text-textColor'>
            <div className='flex justify-between mb-[30px] p-3'>
                <strong className='text-start text-xl text-textColor flex justify-center'>UpdateGateway</strong>
                {(value.adOrg === true || value.adGateway === true)
                    && <div>
                        <Button id={'delete'} textColor={'text-red-600'} border={'border-2 border-red-400'} text={'Delete'} hover={'hover:bg-red-50'} onClick={e => handleSubmit(e.target.id)} />
                    </div>}
            </div>
            <div className='w-full flex '>
                {types.map(type => (
                    <button
                        className={`${active === type ? 'border-b-2 border-blue-400' : 'border-b-2 border-gray-200'} w-full`}
                        key={type}
                        active={active === type}
                        onClick={() => setActive(type)}
                    >
                        <div className={`m-3 font-medium`}>{type}</div>
                    </button>
                ))}
            </div>
            {
                active === 'Gateway Details' &&
                <div className='w-full pb-[40px]'>
                    <div className='w-full flex my-[20px]'>
                        <div className='w-full border-2 rounded-md p-4 mr-[10px] overflow-hidden'>
                            <strong >Gateway Details</strong>
                            <table className='mt-[30px]'>
                                <tbody>
                                    <tr>
                                        <td className='text-start px-5 py-2 border-b-2'>Gateway ID</td>
                                        <td className='text-start px-5 py-2 border-b-2'>{payload.gateway.id}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start px-5 py-2 border-b-2'>Altitude</td>
                                        <td className='text-start px-5 py-2 border-b-2'>{payload.gateway.location.altitude}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-start px-5 py-2 border-b-2'>GPS Coordinates</td>
                                        <td className='text-start px-5 py-2 border-b-2'>
                                            <div>{payload.gateway.location.latitude}</div>
                                            <div>{payload.gateway.location.longitude}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-start px-5 py-2 border-b-2'>Last seen at</td>
                                        <td className='text-start px-5 py-2 border-b-2'>{dateFormat(payload.lastSeenAt, "mmm dS, yyyy hh:MM TT")}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='w-full flex border-2 rounded-md p-2 ml-[10px]'>
                            Maps
                        </div>
                    </div>
                    <div className='w-full border-2 p-4 rounded-md mb-[20px]'>
                        <Line
                            width={'w-1/2'}
                            height={'300'}
                            data={{
                                labels: frame.map(val => [dateFormat(val?.timestamp, "dS")]),
                                datasets: [
                                    {
                                        data: frame.map(value => value?.rxPacketsReceivedOK),
                                        label: "rxReceive",
                                        borderColor: "#3e95cd",
                                        fill: false
                                    }
                                ]
                            }}
                            options={{
                                title: {
                                    display: true,
                                    text: "World population per region (in millions)"
                                },
                                legend: {
                                    display: true,
                                    position: "bottom"
                                },
                                maintainAspectRatio: false
                            }}

                        />
                    </div>
                    <div className='w-full border-2 p-4 rounded-md '>
                        <Line
                            width={'w-1/2'}
                            height={'300'}
                            data={{
                                labels: frame.map(val => [dateFormat(val?.timestamp, "dS")]),
                                datasets: [
                                    {
                                        data: frame.map(value => value?.txPacketsEmitted),
                                        label: "txEmitted",
                                        borderColor: "#3e95cd",
                                        fill: false
                                    }
                                ]
                            }}
                            options={{
                                title: {
                                    display: true,
                                    text: "World population per region (in millions)"
                                },
                                legend: {
                                    display: true,
                                    position: "bottom"
                                },
                                maintainAspectRatio: false
                            }}
                        />
                    </div>
                </div>
            }
            {
                active === 'Gateway Configuration' &&
                <div className='w-full border-2 p-4 mt-[30px]'>
                    <div className='flex flex-nowrap items-centers justify-center overflow:hidden '>
                        {types2.map(type => (
                            <button
                                className={`${active2 === type ? 'border-b-2 border-blue-300' : ''} min-w-[200px] w-1/6 mb-[30px]`}
                                key={type}
                                active={active2 === type}
                                onClick={() => setActive2(type)}
                            >
                                {type}
                            </button>
                        ))}
                    </div>
                    {
                        active2 === 'General' &&
                        <div>
                            <InputForm disable={true} id='name' type='name' label={'Gateway Name'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.gateway.name} onchange={e => handleOnchange(e)} />
                            <div className=' my-2'>
                                <h2 className='text-xs'>Gateway Description</h2>
                                <textarea
                                    wrap='off'
                                    className='w-full text-start border-2 outline-none rounded p-2 resize-none'
                                    name='description'
                                    disabled={true}
                                    rows={2}
                                    value={payload.gateway.description}
                                    onChange={e => handleOnchange(e)}
                                >
                                </textarea>
                            </div>
                            <CheckBox disable={true} id={'discoveryEnabled'} label={'Discovery Enabled'} checked={payload.gateway.discoveryEnabled} onchange={e => handleOnchange(e)} />
                            <InputForm disable={true} id={'altitude'} typeKeyboard={'number'} label={'Gateway altitude(meters)'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.gateway.location.altitude} onchange={e => handleOnchange(e)} />
                            <InputForm id={'email'} type='email' label={'Email to receive gateway status'} setInvalidFields={setInvalidFields} invalidFields={0} value={email} onchange={e => handleOnchange(e)} />
                        </div>
                    }
                    {
                        active2 === 'Tags' &&
                        <div>
                            Tags can be used to store additional key/value data.
                        </div>
                    }
                    {
                        active2 === 'MetaData' &&
                        <div>
                            Metadata can be used by the ChirpStack Gateway Bridge to push information about the gateway (e.g. ip / hostname, serial number, temperatures, ...). This information is automatically updated when gateway statistics are received.
                        </div>
                    }
                    {(value.adServer === true || value.adOrg === true || value.adGateway === true)
                        && <div className='flex justify-end my-2'>
                            <Button id={'update'} text={'Update Gateway'} onClick={e => { handleSubmit(e.target.id) }} />
                        </div>
                    }
                </div>
            }
            {
                active === 'Certificate' &&
                <div className='border-2 p-4 mt-[30px]'>
                    When required by the network, the gateway needs a client certificate in order to connect to the network. This certificate must be configured on the gateway. After generating the certificate, the certificate can only be retrieved once.
                </div>
            }
            {
                active === 'Gateway Discovery' &&
                <div className='border-2 p-4 mt-[30px]'>
                    <h2>No gateway discovery data is available (yet). This could mean:</h2>
                    <div className='ml-[20px]'>
                        No discovery 'ping' was emitted yet<br />

                        The gateway ping feature has been disabled in ChirpStack Application Server<br />

                        The discovery 'ping' was not received by any other gateways
                    </div>
                </div>
            }
            {
                active === 'Live LoRa Frames' &&
                <div>
                    Live LoRa Frames
                </div>
            }
        </div>
    )
}

export default UpdateGateway