import React from 'react'
import Select, { StylesConfig } from 'react-select';
import { colors } from '../ultils/colors';

const { hoverColor, textColor, secondaryColor, primaryColorBg } = colors
const SelectInputForm = ({ options, setValue, val, reverse, def, defaultValue }) => {
    const colorStyles = {
        singleValue: (provided) => ({
            ...provided,
            height: '100%',
            color: textColor,
            paddingTop: '3px',
        }),
        control: styles => ({ ...styles, backgroundColor: primaryColorBg }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = data.color;
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                        ? hoverColor
                        : isFocused
                            ? hoverColor
                            : undefined,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? textColor
                        : textColor,
                cursor: isDisabled ? 'not-allowed' : 'default',
                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? textColor
                            : 'white'
                        : undefined,
                },
            };
        },
        placeholder: (styles) => { return { ...styles, color: textColor } },
    };

    const theme = theme => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: hoverColor,
            primary: 'gray-200',

            // All possible overrides
            // primary: '#2684FF',
            // primary75: '#4C9AFF',
            // primary50: '#B2D4FF',
            // primary25: '#DEEBFF',

            // danger: '#DE350B',
            // dangerLight: '#FFBDAD',

            // neutral0: 'hsl(0, 0%, 100%)',
            // neutral5: 'hsl(0, 0%, 95%)',
            // neutral10: 'hsl(0, 0%, 90%)',
            // neutral20: 'hsl(0, 0%, 80%)',
            // neutral30: 'hsl(0, 0%, 70%)',
            // neutral40: 'hsl(0, 0%, 60%)',
            // neutral50: '#295f2d',
            // neutral60: 'hsl(0, 0%, 40%)',
            // neutral70: 'hsl(0, 0%, 30%)',
            // neutral80: 'hsl(0, 0%, 20%)',
            // neutral90: 'hsl(0, 0%, 10%)',
        }
        // Other options you can use
        // borderRadius: 4
        // baseUnit: 4,
        // controlHeight: 38
        // menuGutter: baseUnit * 2
    });
    return (
        <Select
            placeholder={def}
            defaultValue={defaultValue}
            menuPlacement={reverse}
            name="university"
            value={options.value}
            theme={theme}
            styles={colorStyles}
            options={options}
            onChange={(e) => setValue(prev => ({ ...prev, [val]: e.value }))} />
    )
}

export default SelectInputForm