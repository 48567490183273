import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useRef, useEffect } from 'react'
import icons from '../../ultils/icons'
import { useNavigate } from "react-router-dom";
import DropdownComponent from './DropdownList';
import { useDispatch, useSelector } from 'react-redux'
import GatewayMap from './GatewayMap';


const { OrgSetting, GateWay, OrgUsers, OrgKey, Application, DeviceProfile, ServiceProfile, MultiGroup, AddOrg } = icons;


const SideBar = ({ children, value, setValue }) => {
    const pathList = ['org-users', 'org-api-keys', 'service-profiles', 'device-profiles', 'gateways', 'applications', 'multicast-group', 'create-org']
    const { isLoggedIn } = useSelector(state => state.auth)
    const [select, setSelect] = useState()
    const navigate = useNavigate()
    const handleSelect = (e) => {
        setSelect(e)
        if (e === '1') {
            navigate(pathList[0])
        } else if (e === '2') {
            navigate(pathList[1])
        } else if (e === '3') {
            navigate(pathList[2])
        } else if (e === '4') {
            navigate(pathList[3])
        } else if (e === '5') {
            navigate(pathList[4])
        } else if (e === '6') {
            navigate(pathList[5])
        } else if (e === '7') {
            navigate(pathList[6])
        } else if (e === '8') {
            navigate(pathList[7])
        }
    }
    // const [obj, setObj] = useState({
    //     org: false,
    //     gateway: false,
    //     dev: false
    // })
    // useEffect(() => {
    //     const temp = value.users.find(val => val.email === value.email)
    //     // console.log(temp.isAdmin)
    //     setObj({
    //         'org': temp?.isAdmin,
    //         'gateway': temp?.isGatewayAdmin,
    //         'dev': temp?.isDeviceAdmin
    //     })
    // }, [value.email, value.users])
    // console.log(obj)
    return (

        <div className='flex h-full'>
            {isLoggedIn &&
                <nav className=" bg-primaryColorBg border-r-2 border-gray-400">
                    <ul className={`w-[220px] h-full text-textColor flex flex-col`}>
                        <li>
                            <DropdownComponent value={value} setValue={setValue} />
                        </li>
                        {/* <li className='cursor-pointer hover:bg-blue-300 m-2 flex '>
                                <NavLink to={'org-setting'} className='flex grid-col gap-4'>
                                    <div><FontAwesomeIcon icon={OrgSetting} /></div>
                                    <div> Org.setting</div>
                                </NavLink>
                            </li> */}
                        {value.orgID === "" &&

                            <li id='8' onClick={e => handleSelect(e.currentTarget.id)} className={`w-full cursor-pointer hover:bg-hoverColor ${select === '8' ? 'bg-hoverColor' : ''} p-2 flex`}>
                                <div className='flex grid-col gap-4'>
                                    <div> <FontAwesomeIcon icon={AddOrg} /></div>
                                    <div> Create new Organization</div>
                                </div>
                            </li>

                        }
                        {
                            value.orgID !== "" &&
                            <div>
                                {(value.adOrg === true || value.adServer) &&
                                    <div>
                                        <li
                                            id='1'
                                            onClick={e => handleSelect(e.currentTarget.id)}
                                            className={`cursor-pointer hover:bg-hoverColor ${(select === '1' && window.location.pathname === `/${pathList[0]}`) ? `bg-hoverColor` : ''} p-2 flex`}>
                                            <div className='flex grid-col gap-4'>
                                                <div> <FontAwesomeIcon icon={OrgUsers} /></div>
                                                <div> Org.Users</div>
                                            </div>
                                        </li>
                                    </div>
                                }
                                {/* <li
                                    id='2'
                                    onClick={e => handleSelect(e.currentTarget.id)}
                                    className={`cursor-pointer hover:bg-hoverColor ${(select === '2' && window.location.pathname === `/${pathList[1]}`) ? `bg-hoverColor` : ''} p-2 flex`}>
                                    <div className='flex grid-col gap-4'>
                                        <div><FontAwesomeIcon icon={OrgKey} /></div>
                                        <div> Org.API Keys</div>
                                    </div>
                                </li> */}
                                <li
                                    id='3'
                                    onClick={e => handleSelect(e.currentTarget.id)}
                                    className={`cursor-pointer hover:bg-hoverColor ${(select === '3' && window.location.pathname === `/${pathList[2]}`) ? `bg-hoverColor` : ''} p-2 flex`}>
                                    <div className='flex grid-col gap-4'>
                                        <div><FontAwesomeIcon icon={ServiceProfile} /></div>
                                        <div> Service Profiles</div>
                                    </div>
                                </li>
                                <li
                                    id='4'
                                    onClick={e => handleSelect(e.currentTarget.id)}
                                    className={`cursor-pointer hover:bg-hoverColor ${(select === '4' && window.location.pathname === `/${pathList[3]}`) ? `bg-hoverColor` : ''} p-2 flex`}>
                                    <div className='flex grid-col gap-4'>
                                        <div><FontAwesomeIcon icon={DeviceProfile} /></div>
                                        <div> Device Profiles</div>
                                    </div>
                                </li>
                                <li
                                    id='5'
                                    onClick={e => handleSelect(e.currentTarget.id)}
                                    className={`cursor-pointer hover:bg-hoverColor ${(select === '5' && window.location.pathname === `/${pathList[4]}`) ? `bg-hoverColor` : ''} p-2 flex`} >
                                    <div className='flex grid-col gap-4'>
                                        <div><FontAwesomeIcon icon={GateWay} /></div>
                                        <div> Gateways</div>
                                    </div>
                                </li>
                                <li
                                    id='6'
                                    onClick={e => handleSelect(e.currentTarget.id)}
                                    className={`cursor-pointer hover:bg-hoverColor ${(select === '6' && window.location.pathname === `/${pathList[5]}`) ? `bg-hoverColor` : ''} p-2 flex`}>
                                    <div className='flex grid-col gap-4'>
                                        <div><FontAwesomeIcon icon={Application} /></div>
                                        <div> Applications</div>
                                    </div>
                                </li>
                                <li
                                    id='7'
                                    onClick={e => handleSelect(e.currentTarget.id)}
                                    className={`cursor-pointer hover:bg-hoverColor ${(select === '7' && window.location.pathname === `/${pathList[6]}`) ? `bg-hoverColor` : ''} p-2 flex`}>
                                    <div className='flex grid-col gap-4'>
                                        <div><FontAwesomeIcon icon={MultiGroup} /></div>
                                        <div> Multicast-groups</div>
                                    </div>
                                </li>

                            </div>
                        }
                    </ul>
                </nav>
            }
            { /*  overflow-scroll dung de cho phep scroll ben trong aside chu khong scroll toan bo page */}
            {window.location.pathname === '/' ? <GatewayMap></GatewayMap> : <aside className={`w-full h-full overflow-auto flex justify-center bg-primaryColorBg p-[30px]`}>{children}</aside>}
        </div>

    )
}

export default SideBar