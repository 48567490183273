import React, { useState } from 'react'
import InputForm from '../inputForm'
import CheckBox from '../CheckBox'
import Button from '../Button'
import { postData, getOrgData } from '../../services'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
// import CodeEditor from '@uiw/react-textarea-code-editor'
const Enqueue = ({ value, setValue, setQueue }) => {
    const { devEUI } = useParams()
    const { token } = useSelector(state => state.auth)
    const [invalidFields, setInvalidFields] = useState()
    const [payload, setPayload] = useState({
        devEUI: devEUI,
        confirmed: false,
        data: "",
        fCnt: 0,
        fPort: 0,
        jsonObject: ""
    })
    const types = ["Base64", "Json"]
    const [active, setActive] = useState(types[0])
    const handleCheckbox = (e) => {
        if (e === "confirm") {
            setPayload(pre => {
                return { ...pre, 'confirmed': !payload.confirmed }
            })
        }
        else if (e.target.name === 'Json') {
            setPayload(pre => {
                return { ...pre, 'jsonObject': e.target.value }
            })
        }
    }
    const navigateBarController = (e) => {
        if (e === "Base64") {
            setPayload(pre => {
                return { ...pre, 'jsonObject': "" }
            })
        } else if (e === "Json") {
            setPayload(pre => {
                return { ...pre, 'data': "" }
            })
        }
        setActive(e)
    }
    const handleSubmit = async () => {
        await postData.postDownlinkQueue(token, payload).then(async (val) => {
            await getOrgData.getDownlinkQueue(token, devEUI).then((val) => setQueue(val.data.data.deviceQueueItems))
            // console.log(val)
            setValue(previousState => {
                return { ...previousState, 'close': true, 'message': val.data.msg, 'error': false }
            })
            setPayload({
                devEUI: devEUI,
                confirmed: false,
                data: "",
                fCnt: 0,
                fPort: 0,
                jsonObject: ""
            })
            // navigate(-1)
        })
            .catch((err) => {
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': err.response.data.msg, 'error': true }
                })
            })
        console.log(payload)
    }
    return (
        <div className='border-2 mx-[10px] my-[20px] p-5 overflow-hidden text-textColor'>
            <strong>Enqueue downlink payload</strong>
            <div className='mt-[30px]'>
                <InputForm typeKeyboard={'number'} type='fPort' label={'fPort'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.fPort} setValue={setPayload} />
                <CheckBox id={'confirm'} label={'Confirmed downlink'} checked={payload.confirmed} onchange={e => handleCheckbox(e.target.id)} />
                <div className='flex'>
                    {types.map(type => (
                        <button
                            className={`${active === type ? 'bg-red-200' : 'bg-gray-200'} w-1/6 `}
                            key={type}
                            active={active === type}
                            onClick={() => navigateBarController(type)}
                        >
                            {type}
                        </button>
                    ))}
                </div>
                {active === "Base64"
                    && <div className='mt-[10px]'>
                        <InputForm typeKeyboard={'string'} type='data' label={'Base64 encode string'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.data} setValue={setPayload} />
                    </div>
                }
                {active === "Json"
                    && <div className='mt-[10px]'>
                        <textarea
                            wrap='off'
                            className='w-full text-start border-2 p-2'
                            name='Json'
                            rows={14}
                            value={payload.jsonObject}
                            onChange={e => handleCheckbox(e)}
                            placeholder={''}
                        // defaultValue={{}}
                        >
                        </textarea>
                    </div>
                }
                <div className='flex justify-end mt-[30px]'>
                    <Button textColor={'text-white'} bgColor={'bg-secondaryColor'} text={'Create Device-profile'} onClick={handleSubmit} />
                </div>
            </div>
        </div>
    )
}

export default Enqueue