import React, { useState, useEffect } from 'react'
import { InputForm, Button, CheckBox } from '../../components'
import { apiLogin } from '../../services/auth'
import * as actions from '../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { path } from '../../ultils/constain'

const Login = ({ value, setValue }) => {

    const [payload, setPayload] = useState({
        email: '',
        password: ''
    });

    const [invalidFields, setInvalidFields] = useState([])
    const { isLoggedIn } = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        isLoggedIn && navigate('/')
    }, [isLoggedIn, navigate])


    const handleSubmit = async () => {
        // console.log(payload)
        const invalid = validate(payload)
        if (invalid === 0) dispatch(actions.login(payload))
            .then((val) => {
                console.log(val)
                if (val.type === 'LOGIN_SUCCESS') {
                    setValue(previousState => {
                        return { ...previousState, 'close': true, 'message': 'Login success', 'infoJWT': val.data.infoJWT }
                    })
                } else if (val.type === 'LOGIN_FAIL') {
                    setValue(previousState => {
                        return { ...previousState, 'close': true, 'message': val.data, 'error': true }
                    })
                }
            })
        console.log(invalidFields)
    }

    const validate = (payload) => {
        let invalids = 0
        let fields = Object.entries(payload)
        fields.forEach(item => {
            switch (item[0]) {
                case 'password':
                    if (item[1].length < 5) {
                        setInvalidFields(prev => [...prev, {
                            name: item[0],
                            message: 'Mật khẩu phải lớn hơn 5 kí tự'
                        }])
                        invalids++
                    } else if (item[1] === '') {
                        setInvalidFields(prev => [...prev, {
                            name: item[0],
                            message: 'Mật khẩu không được bỏ trống'
                        }])
                        invalids++
                    }
                    break;
                case 'email':
                    if (item[1] === '') {
                        setInvalidFields(prev => [...prev, {
                            name: item[0],
                            message: 'Email không được bỏ trống'
                        }])
                        invalids++
                    }
                    break;
                default:
                    break;
            }
        })
        return invalids
    }
    const [hidden, setHidden] = useState(true)
    const handleHidden = () => {
        setHidden(!hidden)
        console.log(hidden)
    }

    return (
        <div>
            <div className={`bg-primaryColorBg w-2/5 min-w-[400px] p-[30px] pb-[100px] border-2 border-gray-200 rounded-md shadow-lg shadow-secondaryColor`}>
                <h3 className='text-textColor font-semibold text-2xl text-center'>Đăng Nhập</h3>
                <div className='mt-[20px] w-full flex flex-col gap-3'>
                    <InputForm
                        labelColor={'textColor'}
                        labelSize={'text-normal'}
                        setInvalidFields={setInvalidFields}
                        invalidFields={invalidFields}
                        label={'Email'}
                        value={payload.email}
                        setValue={setPayload}
                        typeKeyboard='email'
                        type={'email'}
                    />
                    <InputForm
                        labelColor={'textColor'}
                        labelSize={'text-normal'}
                        setInvalidFields={setInvalidFields}
                        invalidFields={invalidFields}
                        label={'Mật Khẩu'}
                        value={payload.password}
                        setValue={setPayload}
                        typeKeyboard={hidden && 'password'}
                        type={'password'}
                    />
                    <CheckBox id={'hidden'} label={'Show password?'} checked={!hidden} onchange={e => handleHidden(e)} />
                    <Button text={'Đăng nhập'} bgColor='bg-secondaryColor' textColor='text-white' onClick={handleSubmit} />
                </div>
                <div className='mt-7 flex items-center justify-between'>
                    <h2 className='text-textColor hover:text-[orange] cursor-pointer'>Quên mật khẩu?</h2>
                    <h2 className='text-textColor hover:text-[orange] cursor-pointer' onClick={() => navigate('/signup')}>Tạo tài khoản mới </h2>
                </div>
            </div>
        </div>
    )
}

export default Login