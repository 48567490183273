import React from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'

const PopupCustom = ({ setOpen, open, content, overlayStyle, contentStyle, accept, cancel, close, closeOnDocumentClick }) => {
    return (
        <Popup
            // trigger={<button> Trigger</button>}
            closeOnDocumentClick={closeOnDocumentClick}
            open={open}
            on={'click'}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            position={"center center"}
            overlayStyle={overlayStyle}
            contentStyle={contentStyle}
        >
            <div className={`m-4 ${close && 'flex items-center'}`}>
                {content}
                {
                    close && <button
                        className='flex justify-center items-center absolute w-[30px] h-[30px] right-[0px] top-[0] rounded-xl text-xl text-black'
                        onClick={() => setOpen(false)}
                    >x
                    </button>
                }
            </div>
            {(accept || cancel) &&
                <div className='flex justify-end m-3'>
                    <button id='accept' className='mr-2 p-1 bg-blue-300 rounded-md' onClick={(e) => accept(e.target.id)}>Accept</button>
                    <button className='mr-2 p-1 bg-gray-200 rounded-md' onClick={() => cancel(!open)}>Cancel</button>
                </div>}
        </Popup>
    )
}

export default PopupCustom