import axiosConfig from '../axiosConfig'


export const deleteUserInOrg = (jwt, id, orgID) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            id: id,
            orgID: orgID,
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `bearer ${jwt}`,
            },
            method: 'DELETE',
            data: data,
            url: '/api/delete/org/delete-user',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})
export const deleteKeyInOrg = (jwt, id) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            id: id
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `bearer ${jwt}`,
            },
            method: 'DELETE',
            data: data,
            url: '/api/delete/org/delete-key',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})
export const deleteServiceProfile = (jwt, id) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            id: id
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `bearer ${jwt}`,
            },
            method: 'DELETE',
            data: data,
            url: '/api/delete/org/delete-service-profile',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})
export const deleteDeviceProfile = (jwt, id) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            id: id
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `bearer ${jwt}`,
            },
            method: 'DELETE',
            data: data,
            url: '/api/delete/org/delete-device-profile',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})
export const deleteDownlinkQueue = (jwt, devEUI) => new Promise(async (resolve, reject) => {
    try {
        const data = {
            devEUI: devEUI
        }
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `bearer ${jwt}`,
            },
            method: 'DELETE',
            data: data,
            url: '/api/delete/delete-queue',
            /*
            can not send data in Get request
            use params instead data
            
            
            */
        })
        return resolve(response)
    } catch (error) {
        return reject(error)
    }
})