import React, { useState, useEffect } from 'react'
import { getOrgData } from '../../services/index.js';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Navigation from './Navigation.js';
import { colors } from '../../ultils/colors.js';
import Select, { StylesConfig } from 'react-select';

const { secondaryColor, primaryColorBg, hoverColor, textColor } = colors
export default function DropdownComponent({ value, setValue }) {
    const [orgList, setOrgList] = useState()
    const navigate = useNavigate()
    const { token } = useSelector(state => state.auth)
    let list = []
    for (let i = 0; i < orgList?.length; i++) {
        list[i] = {
            value: orgList[i].id ? orgList[i].id : orgList[i].organizationID,
            label: orgList[i].name ? orgList[i].name : orgList[i].organizationName
        }
    }

    useEffect(() => {
        const fetchOrgList = async (token) => {
            const res = await getOrgData.orgList(token)
            if (res?.data.err === 0) {
                if (res?.data.msg === "Get data successfully") {
                    if (res.data.data.length !== 0) {
                        setOrgList(res.data.data)
                    }
                } else {
                    setOrgList(res.data.data.result)
                }
            }
        }
        if (value.orgID) { fetchOrgList(token) }
    }, [setValue, token, value.orgID])

    useEffect(() => {
        const fetchUser = async (id, token) => {
            await getOrgData.orgUsers(id, token).then(val => {
                const temp = val.data.data.result.find(val => val.email === value.email)
                setValue(previousState => {
                    return { ...previousState, 'users': val.data.data.result, 'adOrg': temp?.isAdmin, 'adGateway': temp?.idGatewayAdmin, 'adDev': temp?.isDeviceAdmin }
                })
            })
        }
        if (value.orgID) { fetchUser(value.orgID, token) }
    }, [setValue, value.email, value.orgID, token])
    useEffect(() => {
        const fetchOrgList = async (token) => {
            const res = await getOrgData.orgList(token)
            if (res?.data.err === 0) {
                if (res?.data.msg === "Get data successfully") {
                    if (res.data.data.length !== 0) {
                        setOrgList(res.data.data)
                        setValue(previousState => {
                            return { ...previousState, 'orgID': res.data.data[0].organizationID }
                        })
                    }
                } else {
                    setOrgList(res.data.data.result)
                    setValue(previousState => {
                        return { ...previousState, 'orgID': res.data.data.result[0].id }
                    })
                }
                // setValue(res.data.data.result[0][0])

            }
            if (res.data.data.length !== 0) {
                const id = res.data.data.length >= 1 ? res.data.data[0].organizationID : res.data.data.result[0].id
                await getOrgData.orgUsers(id, token).then(val => {
                    const temp = val.data.data.result.find(val => val.email === value.email)
                    setValue(previousState => {
                        return { ...previousState, 'users': val.data.data.result, 'adOrg': temp?.isAdmin, 'adGateway': temp?.idGatewayAdmin, 'adDev': temp?.isDeviceAdmin }
                    })
                })
                await getOrgData.orgApiKeys(id, token).then(val => setValue(previousState => {
                    return { ...previousState, 'apiKeys': val.data.data?.result || [] }
                }))
                await getOrgData.orgServices(id, token).then(val => setValue(previousState => {
                    return { ...previousState, 'services': val.data.data.result }
                }))
                await getOrgData.orgDevices(id, token).then(val => setValue(previousState => {
                    return { ...previousState, 'devices': val.data.data.result }
                }))
                await getOrgData.orgGateways(id, token).then(val => setValue(previousState => {
                    return { ...previousState, 'gateways': val.data.data.result }
                }))
                await getOrgData.orgApplications(id, token).then(val => setValue(previousState => {
                    return { ...previousState, 'applications': val.data.data.result }
                }))
                await getOrgData.orgMulticastGroup(id, token).then(val => setValue(previousState => {
                    return { ...previousState, 'multicastGroup': val.data.data.result }
                }))
            }
            await getOrgData.userProfile(token).then(val => setValue(previousState => {
                return { ...previousState, 'email': val.data.data.email, 'adServer': val.data.data.isAdmin }
            }))
        }
        fetchOrgList(token).catch((err) => { console.log(err) })
        // if (value.orgID) {
        //     fetchOrgList(token).catch((err) => { console.log(err) })
        // }
    }, [setValue, token, value.email])

    const handleChange = async (e) => {

        setValue(previousState => {
            return { ...previousState, 'orgID': e }
        })

        await getOrgData.orgUsers(e, token).then(val => {
            const temp = val.data.data.result.find(val => val.email === value.email)
            setValue(previousState => {
                return { ...previousState, 'users': val.data.data.result, 'adOrg': temp?.isAdmin, 'adGateway': temp?.idGatewayAdmin, 'adDev': temp?.isDeviceAdmin }
            })
        })
        await getOrgData.orgApiKeys(e, token).then(val => setValue(previousState => {
            return { ...previousState, 'apiKeys': val.data.data?.result || [] }
        }))
        await getOrgData.orgServices(e, token).then(val => setValue(previousState => {
            return { ...previousState, 'services': val.data.data.result }
        }))
        await getOrgData.orgDevices(e, token).then(val => setValue(previousState => {
            return { ...previousState, 'devices': val.data.data.result }
        }))
        await getOrgData.orgGateways(e, token).then(val => setValue(previousState => {
            return { ...previousState, 'gateways': val.data.data.result }
        }))
        await getOrgData.orgApplications(e, token).then(val => setValue(previousState => {
            return { ...previousState, 'applications': val.data.data.result }
        }))
        await getOrgData.orgMulticastGroup(e, token).then(val => setValue(previousState => {
            return { ...previousState, 'multicastGroup': val.data.data.result }
        }))

    }
    console.log(value.orgID)
    const colorStyles = {
        singleValue: (provided) => ({
            ...provided,
            height: '100%',
            color: textColor,
            paddingTop: '3px',
        }),
        control: styles => ({ ...styles, backgroundColor: primaryColorBg }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = data.color;
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                        ? hoverColor
                        : isFocused
                            ? hoverColor
                            : undefined,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? textColor
                        : textColor,
                cursor: isDisabled ? 'not-allowed' : 'default',
                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? textColor
                            : 'white'
                        : undefined,
                },
            };
        },
        placeholder: (styles) => { return { ...styles, color: textColor } },
    };

    const theme = theme => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: hoverColor,
            primary: '',

            // All possible overrides
            // primary: '#2684FF',
            // primary75: '#4C9AFF',
            // primary50: '#B2D4FF',
            // primary25: '#DEEBFF',

            // danger: '#DE350B',
            // dangerLight: '#FFBDAD',

            // neutral0: 'hsl(0, 0%, 100%)',
            // neutral5: 'hsl(0, 0%, 95%)',
            // neutral10: 'hsl(0, 0%, 90%)',
            // neutral20: 'hsl(0, 0%, 80%)',
            // neutral30: 'hsl(0, 0%, 70%)',
            // neutral40: 'hsl(0, 0%, 60%)',
            // neutral50: '#295f2d',
            // neutral60: 'hsl(0, 0%, 40%)',
            // neutral70: 'hsl(0, 0%, 30%)',
            // neutral80: 'hsl(0, 0%, 20%)',
            // neutral90: 'hsl(0, 0%, 10%)',
        }
        // Other options you can use
        // borderRadius: 4
        // baseUnit: 4,
        // controlHeight: 38
        // menuGutter: baseUnit * 2
    });
    console.log(value)
    return (
        <div className="relative w-full h-full lg:max-w-sm">
            <Select
                theme={theme}
                styles={colorStyles}
                name="orgList"
                options={list}
                // defaultValue={{ value: value.orgID }}
                placeholder={`${list[0]?.label}`}
                onChange={e => { handleChange(e.value); navigate('/') }}
            // className={`w-full p-2.5 text-[${secondaryColor}] bg-[${primaryColorBg}] border shadow-sm outline-none appearance-none focus:border-indigo-600`}
            />
        </div>
    );
}
