import React, { useState, useEffect } from 'react'
import { Button, CusPagination, SelectInputForm } from '../../components'
import { useNavigate } from 'react-router-dom'
import { getOrgData } from '../../services'
import { useDispatch, useSelector } from 'react-redux'
const MulticastGroup = ({ value, setValue }) => {
    const navigate = useNavigate()
    const goAddMulticast = (e) => {
        if (value.services.length === 0) {
            setValue(previousState => {
                return { ...previousState, 'close': true, 'message': 'Organization need to have service profile before adding gateway', 'error': true }
            })
        } else { navigate('add-multicast') }
        console.log(e)
    }
    const [val, setVal] = useState({
        numberPerPage: 10,
        current: []
    })
    const { isLoggedIn, token } = useSelector(state => state.auth)
    useEffect(() => {
        const fetch = async () => {
            await getOrgData.orgApplications(value.orgID, token).then(val => setValue(previousState => {
                return { ...previousState, 'applications': val.data.data.result }
            }))
        }
        fetch()
    }, [setValue, value.orgID, token])
    const list = [
        {
            label: '10',
            value: 10
        },
        {
            label: '25',
            value: 25
        },
        {
            label: '50',
            value: 50
        },
        {
            label: '100',
            value: 100
        },
        {
            label: '200',
            value: 200
        },
        {
            label: '500',
            value: 500
        }
    ]
    return (
        <div className='w-full max-w-[800px] h-full'>
            <div className='flex justify-between mb-[30px]'>
                <div className='text-xl font-medium text-textColor inline-block align-text-bottom'>Multicast Group</div>
                <Button
                    bgColor={'bg-secondaryColor'}
                    textColor={'text-white'}
                    text={'Create'}
                    onClick={goAddMulticast}
                    hover={'hover:bg-blue-200'}
                    border={'border-solid border-2'}
                />
            </div >
            <div className='border-2 p-[10px]'>
                {value.multicastGroup &&
                    <table table='true' className='w-full h-full' >
                        <thead className='border-b-2'>
                            <tr >
                                <th className='text-start text-textColor px-5 py-4'>ID</th>
                                <th className='text-start text-textColor px-5 py-4'>Name</th>
                                <th className='text-start text-textColor px-5 py-4'>Service-profiles</th>
                            </tr>
                        </thead>
                        {val.current.length > 0 && val.current.map((val, key) => {
                            return (
                                <tbody className='border-b-2' key={key}>
                                    <tr>
                                        <td className='text-start px-5 py-2'>{val.id}</td>
                                        <td className='text-start px-5 py-2'>{val.name}</td>
                                        <td className='text-start px-5 py-2'>{val.serviceProfileName}</td>
                                    </tr>
                                </tbody>
                            )
                        })}
                    </table>
                }
                {!value.multicastGroup && <div>null</div>}
                <div className='flex justify-end overflow-visible'>
                    <div className='flex items-center'>
                        <div className='mr-[10px]'>Rows per page</div>
                        <SelectInputForm def={'10'} options={list} setValue={setVal} val={'numberPerPage'} />
                    </div>
                    <CusPagination val={val} value={value.multicastGroup} setVal={setVal} />
                </div>
            </div>

        </div>

    )
}

export default MulticastGroup