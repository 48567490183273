import React, { useState, useCallback } from 'react';
import { Collapse } from 'react-collapse';
import dateFormat from "dateformat";
import icons from '../ultils/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const { Down, Up } = icons;
const Cart = ({ items, status }) => {
    // console.log('items is:', items)
    // console.log('status is:', status)
    const accessibilityIds = {
        checkbox: 'accessible-marker-example1',
        button: 'accessible-marker-example2'
    }
    const [isButtonCollapseOpen, setIsButtonCollapseOpen] = useState(false);
    // const [rxInfo, setRxInfo] = useState(false);
    // const [txInfo, setTxInfo] = useState(false);
    // const [rxItems, setRxItems] = useState(items.rxInfo.map((item, index) => {
    //     return ({
    //         main: false,
    //         location: false
    //     })
    // }));
    // console.log(rxItems)
    const onClick = useCallback(
        () => setIsButtonCollapseOpen(!isButtonCollapseOpen),
        [isButtonCollapseOpen]
    )
    // const onClickController = (e) => {
    //     if (e === 'rxInfo') {
    //         setRxInfo(!rxInfo)
    //     }
    //     if (e === 'txInfo') {
    //         setTxInfo(!txInfo)
    //     }
    //     // console.log(e)
    //     const newState = rxItems.map((obj, index) => {
    //         // 👇️ if id equals 2, update country property
    //         if (`rxItems[${index}]` === e) {
    //             console.log(obj)
    //             return { ...obj, 'main': !obj.main }
    //         }
    //         if (`location[${index}]` === e) {
    //             return { ...obj, 'location': !obj.location }
    //         }
    //         // 👇️ otherwise return the object as is
    //         return obj;
    //     });
    //     setRxItems(newState)
    // }
    return (
        <div>
            {status === 'up' && <div className='w-full bg-primaryColor text-textColor border-2 border-secondaryColor rounded-md my-[10px]'>
                <button
                    className='w-full flex p-[10px]'
                    aria-controls={accessibilityIds.button}
                    aria-expanded={isButtonCollapseOpen}
                    onClick={onClick}
                    type="button">
                    <div className='w-full flex space-x-5'>
                        <div>{dateFormat(items.rxInfo[0]?.time, "h:MM:ss TT")}</div>
                        <div>{status}</div>
                    </div>
                    <div> {isButtonCollapseOpen ? <FontAwesomeIcon icon={Up} /> : <FontAwesomeIcon icon={Down} />}</div>
                </button>
                <div className='h-full px-[10px]'>
                    <Collapse
                        isOpened={isButtonCollapseOpen}>
                        <div id={accessibilityIds.button} className="h-full blob">
                            <div className='flex space-x-3'>
                                <div>
                                    applicationID:
                                </div>
                                <div>
                                    {items.applicationID}
                                </div>
                            </div>
                            <div className='flex space-x-3'>
                                <div>
                                    applicationName:
                                </div>
                                <div>
                                    {items.applicationName}
                                </div>
                            </div>
                            <div className='flex space-x-3'>
                                <div>
                                    deviceName:
                                </div>
                                <div>
                                    {items.deviceName}
                                </div>
                            </div>
                            <div className='flex space-x-3'>
                                <div>
                                    devEUI:
                                </div>
                                <div>
                                    {items.devEUI}
                                </div>
                            </div>
                            <div className='w-full'>
                                {/* <button
                                id='rxInfo'
                                className='w-full flex'
                                aria-controls={accessibilityIds.button}
                                aria-expanded={rxInfo}
                                onClick={e => onClickController(e.target.id)}
                                type="button"
                            >
                                <div className='flex space-x-3'>
                                    <div>
                                        {rxInfo ? <FontAwesomeIcon size='xs' icon={Up} /> : <FontAwesomeIcon size='xs' icon={Down} />}
                                    </div>
                                    
                                </div>
                            </button> */}
                                <div>rxInfo: [ ] {items.rxInfo.length} items</div>
                                <Collapse isOpened={true}>
                                    {items.rxInfo.length !== 0 && items.rxInfo.map((value, index) => {
                                        // console.log(value.channel)
                                        return <div>
                                            <div className='ml-[20px]'>
                                                {/* <button
                                                id={`rxItems[${index}]`}
                                                className='w-full flex'
                                                aria-controls={accessibilityIds.button}
                                                aria-expanded={rxItems[index].main}
                                                onClick={e => onClickController(e.target.id)}
                                                type="button">
                                                <div>
                                                    {rxItems[index].main ? <FontAwesomeIcon size='xs' icon={Up} /> : <FontAwesomeIcon size='xs' icon={Down} />}
                                                </div>
                                                
                                            </button> */}
                                                <div>
                                                    {index}:{Object.keys(value).length}
                                                </div>
                                                <Collapse isOpened={true}>
                                                    <div className='ml-[20px]'>
                                                        <div className='flex space-x-3'>
                                                            <div>
                                                                gatewayID:
                                                            </div>
                                                            <div>
                                                                {value.gatewayID}
                                                            </div>
                                                        </div>
                                                        <div className='flex space-x-3'>
                                                            <div>
                                                                time:
                                                            </div>
                                                            <div>
                                                                {value.time}
                                                            </div>
                                                        </div>
                                                        <div className='flex space-x-3'>
                                                            <div>
                                                                rssi:
                                                            </div>
                                                            <div>
                                                                {value.rssi}
                                                            </div>
                                                        </div>
                                                        <div className='flex space-x-3'>
                                                            <div>
                                                                loRaSNR:
                                                            </div>
                                                            <div>
                                                                {value.loRaSNR}
                                                            </div>
                                                        </div>
                                                        <div className='flex space-x-3'>
                                                            <div>
                                                                channel:
                                                            </div>
                                                            <div>
                                                                {value.channel !== undefined ? value.chanel : '0'}
                                                            </div>
                                                        </div>
                                                        <div className='flex space-x-3'>
                                                            <div>
                                                                rfChain:
                                                            </div>
                                                            <div>
                                                                {value.rfChain !== undefined ? value.rfChain : '0'}
                                                            </div>
                                                        </div>
                                                        <div className='flex space-x-3'>
                                                            <div>
                                                                board:
                                                            </div>
                                                            <div>
                                                                {value.board !== undefined ? value.board : '0'}
                                                            </div>
                                                        </div>
                                                        <div className='flex space-x-3'>
                                                            <div>
                                                                antenna:
                                                            </div>
                                                            <div>
                                                                {value.antenna !== undefined ? value.antenna : '0'}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {/* <button
                                                            id={`location[${index}]`}
                                                            className='w-full flex space-x-4'
                                                            aria-controls={accessibilityIds.button}
                                                            aria-expanded={rxItems[index].location}
                                                            onClick={e => onClickController(e.target.id)}
                                                            type="button">
                                                            <span aria-hidden={true}>
                                                                {rxItems[index].location ? <FontAwesomeIcon size='xs' icon={Up} /> : <FontAwesomeIcon size='xs' icon={Down} />}
                                                            </span>
                                                            
                                                        </button> */}
                                                            <div>location: {Object.keys(value.location).length} keys</div>
                                                            <Collapse isOpened={true}>
                                                                <div className='ml-[20px]'>
                                                                    <div className='flex space-x-3'>
                                                                        <div>
                                                                            latitude:
                                                                        </div>
                                                                        <div>
                                                                            {value.location.latitude}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='ml-[20px]'>
                                                                    <div className='flex space-x-3'>
                                                                        <div>
                                                                            longitude:
                                                                        </div>
                                                                        <div>
                                                                            {value.location.longitude}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='ml-[20px]'>
                                                                    <div className='flex space-x-3'>
                                                                        <div>
                                                                            altitude:
                                                                        </div>
                                                                        <div>
                                                                            {value.location.altitude}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='ml-[20px]'>
                                                                    <div className='flex space-x-3'>
                                                                        <div>
                                                                            source:
                                                                        </div>
                                                                        <div>
                                                                            {value.location.source !== undefined ? value.location.source : 'UNKNOWN'}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='ml-[20px]'>
                                                                    <div className='flex space-x-3'>
                                                                        <div>
                                                                            accuracy:
                                                                        </div>
                                                                        <div>
                                                                            {value.location.accuracy !== undefined ? value.location.accuracy : '0'}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                        <div className='flex space-x-3'>
                                                            <div>
                                                                antenna:
                                                            </div>
                                                            <div>
                                                                {value.antenna !== undefined ? value.antenna : '0'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='ml-[20px]'>
                                                        <div className='flex space-x-3'>
                                                            <div>
                                                                Name:
                                                            </div>
                                                            <div>
                                                                {value.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='ml-[20px]'>
                                                        <div className='flex space-x-3'>
                                                            <div>
                                                                uplinkID:
                                                            </div>
                                                            <div>
                                                                {value.uplinkID}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </div>
                                    })}

                                </Collapse>
                            </div>
                            <div>
                                {/* <button
                                id='txInfo'
                                className='flex space-x-4'
                                aria-controls={accessibilityIds.button}
                                aria-expanded={txInfo}
                                onClick={e => onClickController(e.target.id)}
                                type="button">
                                <div className='mr-[5px]'> {txInfo ? <FontAwesomeIcon size='xs' icon={Up} /> : <FontAwesomeIcon size='xs' icon={Down} />}</div>
                                
                            </button> */}
                                <div>txInfo:</div>
                                <Collapse isOpened={true}>
                                    <div className='ml-[20px]'>
                                        <div className='flex space-x-3'>
                                            <div>
                                                frequency:
                                            </div>
                                            <div>
                                                {items.txInfo.frequency}
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>
                            </div>

                            <div className='flex space-x-3'>
                                <div>
                                    adr:
                                </div>
                                <div>
                                    {items.adr === true ? 'true' : 'false'}
                                </div>
                            </div>
                            <div className='flex space-x-3'>
                                <div>
                                    dr:
                                </div>
                                <div>
                                    {items.txInfo.dr}
                                </div>
                            </div>
                            <div className='flex space-x-3'>
                                <div>
                                    fCnt:
                                </div>
                                <div>
                                    {items.fCnt}
                                </div>
                            </div>
                            <div className='flex space-x-3'>
                                <div>
                                    fPort:
                                </div>
                                <div>
                                    {items.fPort}
                                </div>
                            </div>
                            <div className='flex space-x-3'>
                                <div>
                                    data:
                                </div>
                                <div>
                                    {items.data}
                                </div>
                            </div>
                            <div className='flex space-x-3'>
                                {(items.object === null || items.object === undefined)
                                    ? <div>objectJSON: {typeof (items.object)}</div>
                                    : <div>
                                        objectJSON: {'{ }'} {Object.keys(items.object).length} keys
                                    </div>
                                }

                            </div>
                            {(items.object !== null && items.object !== undefined)
                                && Object.entries(items.object).length > 0
                                && Object.entries(items.object)
                                    .map(entry => {
                                        // console.log(items.object)
                                        let [key, value] = entry
                                        // console.log(entry)
                                        return <div className='ml-[20px]'>
                                            <div className='flex space-x-3'>
                                                {typeof (value) !== 'object'
                                                    ? <div>{key}:</div>
                                                    : <div>
                                                        {key}: {'{ }'} {Object.keys(value).length} keys
                                                    </div>
                                                }
                                                {typeof (value) !== 'object' && <div>
                                                    {value}
                                                </div>}
                                            </div>
                                            {typeof (value) === 'object'
                                                && Object.entries(value)
                                                    .map(entry => {
                                                        let [key, value] = entry
                                                        return <div className='ml-[20px]'>
                                                            <div className='flex space-x-3'>
                                                                {typeof (value) !== 'object'
                                                                    ? <div>{key}:</div>
                                                                    : <div>
                                                                        {key}: {'{ }'} {Object.keys(value).length} keys
                                                                    </div>
                                                                }
                                                                {typeof (value) !== 'object'
                                                                    && <div>
                                                                        {value}
                                                                    </div>}
                                                            </div>
                                                            {typeof (value) === 'object'
                                                                && Object.entries(value)
                                                                    .map(entry => {
                                                                        let [key, value] = entry
                                                                        return <div className='ml-[20px]'>
                                                                            <div className='flex space-x-3'>
                                                                                <div>
                                                                                    {key}:
                                                                                </div>
                                                                                <div>
                                                                                    {value}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    })}
                                                        </div>
                                                    })}
                                        </div>
                                    }

                                    )}
                            <div className='flex space-x-3'>
                                <div>
                                    tags:
                                </div>
                                <div>
                                    {items.tags ? items.tags : '{} 0 keys'}
                                </div>
                            </div>
                            <div className='flex space-x-3'>
                                <div>
                                    confirmedUplink:
                                </div>
                                <div>
                                    {items.confirmedUplink === true ? 'true' : 'false'}
                                </div>
                            </div>
                            <div className='flex space-x-3'>
                                <div>
                                    defAddr:
                                </div>
                                <div>
                                    {items.defAddr}
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>}
            {/* {status !== 'error'
                && <div className='w-full bg-primaryColor text-textColor border-2 border-red-400 rounded-md my-[10px]'>
                </div>
            } */}
        </div>
    )
}

export default Cart