import React, { useState, useEffect } from 'react'
import Header from './Header'
import { Outlet } from 'react-router-dom'
import Navigation from './Navigation'
import SideBar from './SideBar'
import { PopupCustom } from '../../components'
import { colors } from '../../ultils/colors'


const { secondaryColor } = colors
const Home = ({ value, setValue }) => {
    // const [openPopup, setOpenPopup] = useState(false)
    // useEffect(() => {
    //     if (value.close) {
    //         setOpenPopup(true);
    //     } else (
    //         setOpenPopup(false)
    //     )
    // }, [value.close, openPopup, setOpenPopup]);
    const setOpen = (e) => {
        if (e) {
            setValue(previousState => {
                return { ...previousState, 'close': e }
            })
        } else {
            setValue(previousState => {
                return { ...previousState, 'close': e, 'message': '', 'error': e }
            })
        }
    }
    return (
        <div className='h-[100%] w-full flex flex-col'>
            <PopupCustom
                open={value.close}
                setOpen={setOpen}
                close={setOpen}
                content={`${value.message}`}
                contentStyle={{
                    width: "auto",
                    backgroundColor: `${value.error ? 'red' : secondaryColor}`,
                    border: "1px solid #e4e7ec",
                    boxShadow: "0px 10px 40px 10px rgb(0 0 0 / 10%)",
                    borderRadius: 20,
                    // make popup bottom right//
                    position: "absolute",
                    bottom: "10px",
                    right: "10px"
                }}
                overlayStyle={{
                    backgroundColor: "rgb(0 0 0 / 1%)"
                }}
            >
            </PopupCustom>
            <div className={`w-full h-[12%] flex-0 bg-primaryColorBg`}>
                <Header value={value} setValue={setValue} />
            </div>
            {/* <div className='w-full h-[6%] flex-0'>
                <Navigation value={value.sideBar} setValue={setValue} />
            </div> */}
            <div className='h-[88%] overflow-hidden'>
                <SideBar value={value} setValue={setValue}>
                    <Outlet />
                </SideBar>
            </div>
        </div>
    )
}

export default Home