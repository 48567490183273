import React, { useState, useEffect } from 'react'
import { InputForm, CheckBox, Button, SelectInputForm } from '../../../components'
import { getOrgData, postData } from '../../../services'
import { useNavigate } from 'react-router-dom'



const AddServiceProfile = ({ value, setValue }) => {
    const [invalidFields, setInvalidFields] = useState()
    const [nwList, setNwList] = useState()
    const navigate = useNavigate()
    let options = []
    useEffect(() => {
        const fetchData = async () => {
            const res = await getOrgData.networkServer().catch(err => console.log(err))
            setNwList(res.data.data.result)
        }

        fetchData()
    }, [])
    for (let i = 0; i < nwList?.length; i++) {
        options = [{
            value: nwList[i].id,
            label: nwList[i].name
        }]
    }
    const handleMetaData = (e) => {
        if (e === 'geo') {
            setPayload(pre => {
                return { ...pre, 'nwkGeoLoc': !payload.nwkGeoLoc }
            })
        } else {
            setPayload(pre => {
                return { ...pre, 'addGWMetaData': !payload.addGWMetaData }
            })
        }
    }

    const [payload, setPayload] = useState(
        {
            addGWMetaData: false,
            devStatusReqFreq: 0,
            drMax: 0,
            drMin: 0,
            name: '',
            networkServerID: '',
            nwkGeoLoc: false,
            organizationID: value.orgID
        }
    )

    const handleOnclick = async () => {
        postData.postServiceProfile(payload).then((val) => {
            console.log(val)
            setValue(previousState => {
                return { ...previousState, 'close': true, 'message': val.data.msg, 'error': false }
            })
            navigate(-1)
        })
            .catch((err) => {
                setValue(previousState => {
                    return { ...previousState, 'close': true, 'message': err.response.data.msg, 'error': true }
                })
            })
    }

    return (
        <div className='h-full w-full max-w-[800px] p-[10px]'>
            <div className='p-[10px] text-xl font-medium text-textColor inline-block align-text-bottom'>Add Service Profile</div>
            <div className='border px-[10px] py-[20px] text-textColor rounded-md'>
                <InputForm required={true} type='name' label={'Service Profile Name'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.name} setValue={setPayload} />
                <div className='my-[10px]'>
                    <h2 className='text-xs'>Network Server</h2>
                    <SelectInputForm options={options} val={'networkServerID'} setValue={setPayload} />
                </div>
                <CheckBox id={'meta'} label={'Add gateway meta-data'} checked={payload.addGWMetaData} onchange={e => handleMetaData(e.target.id)} />
                <CheckBox id={'geo'} label={'Enable network geolocation'} checked={payload.nwkGeoLoc} onchange={e => handleMetaData(e.target.id)} />
                <InputForm typeKeyboard={'number'} type='devStatusReqFreq' label={'Device-status request frequency'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.devStatusReqFreq} setValue={setPayload} />
                <InputForm typeKeyboard={'number'} type='drMin' label={'Minimum allow data-rate'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.drMin} setValue={setPayload} />
                <InputForm typeKeyboard={'number'} type='drMax' label={'Maximum allow data-rate'} setInvalidFields={setInvalidFields} invalidFields={0} value={payload.drMax} setValue={setPayload} />

                <div className='flex justify-end mt-[10px]'>
                    <Button
                        bgColor={'bg-secondaryColor'}
                        textColor={'text-white'}
                        text={'Create'}
                        hover={'hover:bg-blue-200'}
                        onClick={handleOnclick}
                    />
                </div>
            </div>
        </div>

    )
}

export default AddServiceProfile