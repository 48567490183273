export const randomHexString = (length) => {
    let result = [];
    let hexRef = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];

    // for the given length
    for (let n = 0; n < length; n++) {

        // pick a random unit and store it in result
        result.push(hexRef[Math.floor(Math.random() * 16)]);
    }

    // return the result as a string
    return result.join('');
}