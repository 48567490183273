import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
const CusPagination = ({ val, value, setVal }) => {
    const [itemOffset, setItemOffset] = useState(0);

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)

    const itemsPerPage = val.numberPerPage
    const endOffset = (itemOffset + itemsPerPage) < value.length ? (itemOffset + itemsPerPage) : (value.length);
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);

    const pageCount = Math.ceil(value.length / itemsPerPage);
    useEffect(() => {
        const currentItems = value.length === 0 ? [] : value.slice(itemOffset, endOffset);
        setVal(pre => {
            return { ...pre, 'current': currentItems }
        })
    }, [itemOffset, endOffset, value, setVal])
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % value.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    return (
        <div className='flex items-center m-[10px]'>
            <div className='ml-[20px] mr-[10px]'>
                {value.length !== 0 ? (itemOffset + 1) : (0)}-{endOffset} of {value.length}
            </div>
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                nextClassName={`text-xl font-bold`}
                nextLinkClassName={'px-[5px]'}
                disabledLinkClassName={'cursor-context-menu text-gray-400'}
                onPageChange={handlePageClick}
                pageClassName='hidden'
                pageCount={pageCount}
                previousLabel="<"
                previousClassName={`text-xl font-bold`}
                previousLinkClassName={'px-[5px]'}
                renderOnZeroPageCount={null}
                className='flex justify-end m-[10px]'

            />
        </div>
    )
}

export default CusPagination