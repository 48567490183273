import { apiLogin, apiRegister, postData } from "../../services/index.js";
import actionTypes from "./actionTypes";

export const login = (payload) => async (dispatch) => {
    try {
        const response = await apiLogin(payload)
        console.log(response)
        if (response.status === 200) {
            return dispatch({
                type: actionTypes.LOGIN_SUCCESS,
                data: response.data
            })
        } else if (response.status === 401) {
            return dispatch({
                type: actionTypes.LOGIN_FAIL,
                data: response.data.error
            })
        }
    } catch (error) {
        return dispatch({
            type: actionTypes.LOGIN_FAIL,
            data: error.response.data.msg
        })

    }

}

export const logout = () => async (dispatch) => {
    return dispatch({
        type: actionTypes.LOGOUT
    })
}

export const register = (payload) => async (dispatch) => {
    try {
        const response = await apiRegister(payload)
        // console.log(response.data.error)
        if (response.status === 200) {
            return dispatch({
                type: actionTypes.REGISTER_SUCCESS,
                data: response.data.token
            })
        }
        // else if (response.status === 401) {
        //     return dispatch({
        //         type: actionTypes.REGISTER_FAIL,
        //         data: response.data.error
        //     })
        // }
    } catch (error) {
        return dispatch({
            type: actionTypes.REGISTER_FAIL,
            data: error.response.data
        })
    }
}

export const addOrg = (payload) => async (dispatch) => {
    if (payload) {
        try {
            const response = await postData.postNewOrg(payload)
            // console.log(response.data.error)
            if (response.status === 200) {
                return dispatch({
                    type: actionTypes.ADD_ORG_SUCCESS,
                    data: response.data.id
                })
            }
            // else if (response.status === 401) {
            //     return dispatch({
            //         type: actionTypes.REGISTER_FAIL,
            //         data: response.data.error
            //     })
            // }
        } catch (error) {
            return dispatch({
                type: actionTypes.ADD_ORG_FAIL,
                data: error.response.data
            })
        }
    } else {
        return dispatch({
            type: actionTypes.ADD_ORG_SKIP,
            data: payload
        })
    }

}