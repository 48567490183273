import React, { useCallback, useState, useEffect } from 'react'
import { Button, CusPagination, SelectInputForm } from '../../components'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import icons from '../../ultils/icons'
import { deleteData, getOrgData } from '../../services'
import { useSelector } from 'react-redux'
import { colors } from '../../ultils/colors'

const { secondaryColor } = colors
const { Trash } = icons;
const OrgApiKey = ({ value, setValue }) => {
    const navigate = useNavigate();
    const { token } = useSelector(state => state.auth)
    const goAddUser = useCallback(() => {
        navigate('add-key')
        //navigate to path org-users/add-user
    })

    const deleteKey = async (id) => {
        const res = await deleteData.deleteKeyInOrg(id, token).catch(err => console.log(err))
        console.log('res in addActiveDevice function', res)
    }
    useEffect(() => {
        const fetch = async () => {
            await getOrgData.orgApiKeys(value.orgID, token).then(val => setValue(previousState => {
                return { ...previousState, 'apiKeys': val.data.data?.result || [] }
            }))
        }
        fetch()
    }, [setValue, value.orgID, token])
    const handleOnclick = async (e) => {
        // console.log(e)
        await deleteKey(e)
    }
    const [val, setVal] = useState({
        numberPerPage: 10,
        current: []
    })
    const list = [
        {
            label: '10',
            value: 10
        },
        {
            label: '25',
            value: 25
        },
        {
            label: '50',
            value: 50
        },
        {
            label: '100',
            value: 100
        },
        {
            label: '200',
            value: 200
        },
        {
            label: '500',
            value: 500
        }
    ]

    return (
        <div className='w-full max-w-[800px] h-full'>
            <div className='flex justify-between mb-[30px]'>
                <div className='text-xl font-medium text-textColor inline-block align-text-bottom'>Organization API Keys</div>
                <Button
                    text={'Create'}
                    bgColor={'bg-secondaryColor'}
                    textColor={'text-white'}
                    onClick={goAddUser}
                    hover={'hover:bg-blue-200'}
                    border={'border-solid border-2'}
                />
            </div>
            <div className='border-2 p-[10px]'>
                <table table="true" className='w-full h-full' >
                    <thead className='border-b-2'>
                        <tr>
                            <th className='text-start text-textColor px-5 py-4'>ID</th>
                            <th className='text-start text-textColor px-5 py-4'>Name</th>
                        </tr>
                    </thead>
                    {val.current.length > 0 && val.current.map((val, key) => {
                        return (
                            <tbody className='border-b-2' key={key}>
                                <tr>
                                    <td className='text-start px-5 py-2'>{val.id}</td>
                                    <td className='flex justify-between px-5 py-2 mr-[20px]'>
                                        <div>{val.name}</div>
                                        <Button id={val.id} onClick={e => handleOnclick(val.id)} icAfter={Trash} iconColor={secondaryColor} />
                                    </td>
                                </tr>
                            </tbody>
                        )
                    })}
                </table>
                <div className='flex justify-end overflow-visible'>
                    <div className='flex items-center'>
                        <div className='mr-[10px]'>Rows per page</div>
                        <SelectInputForm def={'10'} options={list} setValue={setVal} val={'numberPerPage'} />
                    </div>
                    <CusPagination val={val} value={value.apiKeys} setVal={setVal} />
                </div>
            </div>
        </div>
    )
}

export default OrgApiKey