import axiosConfig from '../axiosConfig'



export const putUserWithID = (token, payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `bearer ${token}`,
            },
            method: 'PUT',
            url: '/api/put/user',
            data: JSON.stringify(payload)
        })
        resolve(response)
    } catch (error) {
        reject(error)
    }
})


export const putServiceProfileWithID = (token, payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `bearer ${token}`,
            },
            method: 'PUT',
            url: '/api/put/service-profile',
            data: JSON.stringify(payload)
        })
        resolve(response)
    } catch (error) {
        reject(error)
    }
})

export const putDeviceProfileWithID = (token, payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `bearer ${token}`,
            },
            method: 'PUT',
            url: '/api/put/device-profile',
            data: JSON.stringify(payload)
        })
        resolve(response)
    } catch (error) {
        reject(error)
    }
})

export const putGatewayDetail = ({ token, id, name, boards, description, discoveryEnabled, location, metadata, tags }) => new Promise(async (resolve, reject) => {
    let data = {
        id: id,
        name: name,
        boards: boards,
        description: description,
        discoveryEnabled: discoveryEnabled,
        location: location,
        metadata: metadata,
        tags: tags
    }
    try {
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `bearer ${token}`,
            },
            method: 'PUT',
            url: '/api/put/gateway-details',
            data: JSON.stringify(data)
        })
        resolve(response)
    } catch (error) {
        reject(error)
    }
})