import axiosConfig from '../axiosConfig'



export const postNewOrg = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'POST',
            url: '/api/post/new/org',
            data: JSON.stringify(payload)
        })
        resolve(response)
    } catch (error) {
        reject(error)
    }
})

export const postOrgUser = (token, payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `bearer ${token}`,
            },
            method: 'POST',
            url: '/api/post/new/org-user',
            data: JSON.stringify(payload)
        })
        resolve(response)
    } catch (error) {
        reject(error)
    }
})


export const postOrgKey = (token, payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `bearer ${token}`,
            },
            method: 'POST',
            url: '/api/post/new/org-key',
            data: JSON.stringify(payload)
        })
        resolve(response)
    } catch (error) {
        reject(error)
    }
})

export const postServiceProfile = (payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'POST',
            url: '/api/post/new/service-profile',
            data: JSON.stringify(payload)
        })
        resolve(response)
    } catch (error) {
        reject(error)
    }
})

export const postDeviceProfile = (token, payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `bearer ${token}`,
            },
            method: 'POST',
            url: '/api/post/new/device-profile',
            data: JSON.stringify(payload)
        })
        resolve(response)
    } catch (error) {
        reject(error)
    }
})

export const postGateway = (token, payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `bearer ${token}`,
            },
            method: 'POST',
            url: '/api/post/new/gateway',
            data: JSON.stringify(payload)
        })
        resolve(response)
    } catch (error) {
        reject(error)
    }
})

export const postApplication = (token, payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `bearer ${token}`,
            },
            method: 'POST',
            url: '/api/post/new/application',
            data: JSON.stringify(payload)
        })
        resolve(response)
    } catch (error) {
        reject(error)
    }
})

export const postDevice = (token, payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `bearer ${token}`,
            },
            method: 'POST',
            url: '/api/post/new/device',
            data: JSON.stringify(payload)
        })
        resolve(response)
    } catch (error) {
        reject(error)
    }
})

export const postActiveDevice = (token, payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `bearer ${token}`,
            },
            method: 'POST',
            url: '/api/post/new/active-device',
            data: JSON.stringify(payload)
        })
        resolve(response)
    } catch (error) {
        reject(error)
    }
})

export const postMulticast = (token, payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `bearer ${token}`,
            },
            method: 'POST',
            url: '/api/post/new/multicast',
            data: JSON.stringify(payload)
        })
        resolve(response)
    } catch (error) {
        reject(error)
    }
})

export const postDownlinkQueue = (token, payload) => new Promise(async (resolve, reject) => {
    try {
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `bearer ${token}`,
            },
            method: 'POST',
            url: '/api/post/new/queue',
            data: JSON.stringify(payload)
        })
        resolve(response)
    } catch (error) {
        reject(error)
    }
})

export const postEmail = (id, email) => new Promise(async (resolve, reject) => {
    const data = {
        id: id,
        email: email
    }
    try {
        const response = await axiosConfig({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                // 'Authorization': `bearer ${token}`,
            },
            method: 'POST',
            url: '/api/post/new/email',
            data: JSON.stringify(data)
        })
        resolve(response)
    } catch (error) {
        reject(error)
    }
})